import { useMutation } from '@tanstack/react-query'
import { StoreApi } from 'zustand'
import { createVoiceRecordMutation } from './voice-record.api'
import { voiceModel } from 'entities/voice'

const keys = {
    root: () => ['voiceRecord'],
    createVoiceRecord: () => [...keys.root(), 'createVoiceRecord'],
}

/**
 * @description Custom hook for creating a voice record mutation.
 *
 * @param voiceStore - Zustand store for managing voice-related state.
 * @returns Mutation function for creating a voice record.
 */
export const useCreateVoiceRecordMutation = (
    voiceStore: StoreApi<voiceModel.State>
) => {
    return useMutation({
        mutationKey: keys.createVoiceRecord(),
        mutationFn: createVoiceRecordMutation,
        onSuccess: (data) => {
            return {
                voiceRecords: [
                    ...(voiceStore.getState().voiceRecords || []),
                    data,
                ],
            }
        },
    })
}
