import { createContext, useState } from 'react'

type DatasetContextType = {
    visibility?: boolean
    isModalBestPracticeOpen?: boolean
    isModalBestPracticeTrigger?: boolean
    isModalLockedOpen?: boolean
    setIsBestPracticeTrigger?: (isBestPracticeTrigger: boolean) => void
    setIsModalBestPracticeOpen?: (isModalBestPracticeOpen: boolean) => void
    setIsModalLockedOpen?: (isModalLockedOpen: boolean) => void
}


export const DatasetContext = createContext<DatasetContextType>({
    visibility: false,
    isModalBestPracticeOpen: false,
    isModalBestPracticeTrigger: false,
    isModalLockedOpen: false,
    setIsModalBestPracticeOpen: () => {},
    setIsModalLockedOpen: () => {},
})

export const DatasetProvider = ({ children }: { children: React.ReactNode }) => {
    const [isModalBestPracticeOpen, setIsModalBestPracticeOpen] = useState(false)
    const [isModalBestPracticeTrigger, setIsBestPracticeTrigger] = useState(false)
    const [isModalLockedOpen, setIsModalLockedOpen] = useState(false)

    return (
        <DatasetContext.Provider
            value={{
                isModalBestPracticeOpen: isModalBestPracticeOpen,
                setIsModalBestPracticeOpen: setIsModalBestPracticeOpen,
                isModalBestPracticeTrigger: isModalBestPracticeTrigger,
                setIsBestPracticeTrigger: setIsBestPracticeTrigger,
                isModalLockedOpen: isModalLockedOpen,
                setIsModalLockedOpen: setIsModalLockedOpen,
            }}
        >
            {children}
        </DatasetContext.Provider>
    )
}
