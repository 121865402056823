import React from "react";
import * as _Builtin from "../_Builtin";
import { ButtonIcon } from "../_Component/ButtonIcon";
import { ButtonText } from "../_Component/ButtonText";
import { ButtonTextSmall } from "../_Component/ButtonTextSmall";

export function DialogGenerateOptionsButtons({
  as: _Component = _Builtin.Block,
  rpGenerateYouAsAPlayer = {},
  rpGenerateLeft = {},
  rpGenerateMiddle = {},
  rpGenerateRight = {},
}) {


  return (
    <_Component
      id="w-node-_57f6b37a-7b99-b30f-f9c3-ea12f9f55583-f9f55583"
      tag="div"
    > 
    <_Builtin.Block className="flex-center ">
        <_Builtin.Link
        className=""
        button={false}
        block="inline"
        options={{
          href: "#",
        }}
        {...rpGenerateYouAsAPlayer}
      >
        <_Builtin.Block className="slot-button" tag="div">
          <ButtonIcon buttonIconText="" />
          <ButtonText buttonText="You as a player generation" />
        </_Builtin.Block>
      </_Builtin.Link>
    </_Builtin.Block>
<_Builtin.Block className="generate-btn-wrapper">
      <_Builtin.Link
        button={false}
        block="inline"
        options={{
          href: "#",
        }}
        {...rpGenerateLeft}
      >
        <_Builtin.Block className="slot-button" tag="div">
          <ButtonIcon buttonIconText="" />
          <ButtonText buttonText="Short generation" />
          <ButtonTextSmall />
        </_Builtin.Block>
      </_Builtin.Link>
      <_Builtin.Link
        button={false}
        block="inline"
        options={{
          href: "#",
        }}
        {...rpGenerateMiddle}
      >
        <_Builtin.Block className="slot-button" tag="div">
          <ButtonIcon buttonIconText="" />
          <ButtonText buttonText="Medium generation" />
          <ButtonTextSmall buttonTextSmall="(6 lines)" />
        </_Builtin.Block>
      </_Builtin.Link>
      <_Builtin.Link
        button={false}
        block="inline"
        options={{
          href: "#",
        }}
        {...rpGenerateRight}
      >
        <_Builtin.Block className="slot-button" tag="div">
          <ButtonIcon buttonIconText="" />
          <ButtonText buttonText="Long generation" />
          <ButtonTextSmall buttonTextSmall="(8 lines)" />
        </_Builtin.Block>
      </_Builtin.Link>
      </_Builtin.Block>
    </_Component>
  );
}
