import { StateCreator } from 'zustand'
import { characterTypes } from 'entities/character'
import { emotionTypes } from 'entities/emotion'
import { rollRecordTypes } from 'entities/roll-record'
import { presetTypes } from 'entities/preset'

// Define the state shape
export type State = {
    selectedCharacter?: characterTypes.Npc
    selectedPreset?: presetTypes.Preset
    selectedEmotion?: emotionTypes.Emotion
    rollRecords?: rollRecordTypes.RollRecords
    rollQuestion?: rollRecordTypes.RollQuestion
    startEndIndex?: number
    dialogLines?: any
}

// Define the actions that can be performed on the state
export type Actions = {
    setSelectedCharacter: (character: characterTypes.Npc) => void
    setSelectedEmotion: (emotion: emotionTypes.Emotion) => void
    addRollRecord: (rollRecord: rollRecordTypes.RollRecord) => void
    removeRollRecord: (index: number) => void
    updateRollRecord: (
        index: number,
        rollRecord: rollRecordTypes.RollRecord,
    ) => void
    updateRollQuestion: ( rollQuestion: rollRecordTypes.RollQuestion ) => void
    save: () => void
    load: () => void
    reset: () => void
}

// Combine state and actions into a unified type
export type RollState = State & Actions

export const createRollSlice =
    (
        initialState: State
    ): StateCreator<
        RollState,
        [['zustand/devtools', never]],
        [],
        RollState
    > =>
    (set) => ({
        ...initialState,

        // Action to set the selected character
        setSelectedCharacter: (character: characterTypes.Npc) =>
            set({ selectedCharacter: character }),
        
        // Action to set the selected preset
        setSelectedPreset: (preset: presetTypes.Preset) =>
            set({ selectedPreset: preset }),

        // Action to set the selected emotion
        setSelectedEmotion: (emotion: emotionTypes.Emotion) =>
            set({ selectedEmotion: emotion }),

        // Action to add a roll record to the list
        addRollRecord: (rollRecord: rollRecordTypes.RollRecord) =>
            set((state) => ({
                rollRecords: [...(state.rollRecords || []), rollRecord],
            })),

        // Action to remove a roll record from the list
        removeRollRecord: (index: number) =>
            set((state) => {
                if (state.rollRecords?.length === 1) {
                    return {
                        rollRecords: state.rollRecords.map((rollRecord) => ({
                            ...rollRecord,
                            response: '',
                        })),
                    }
                } else {
                    return {
                        rollRecords: state.rollRecords?.filter(
                            (_, i) => i !== index
                        ),
                    }
                }
            }),

        // Action to update a roll record in the list
        updateRollRecord: (
            index: number,
            rollRecord: rollRecordTypes.RollRecord
        ) =>
            set((state) => ({
                rollRecords: state.rollRecords?.map((record, i) =>
                    i === index ? rollRecord : record
                ),
            })),

            updateRollQuestion: (
                rollQuestion: rollRecordTypes.RollQuestion
            ) =>
            set((state) => ({rollQuestion: rollQuestion} )),

        // Action to save the current state to session storage
             save: () =>
            set((state) => {
                sessionStorage.setItem('rollState', JSON.stringify(state))
                return state
            }),

        // Action to load the current state from session storage
        load: () => {
            const savedState = sessionStorage.getItem('rollState')
            if (savedState) {
                set(JSON.parse(savedState))
            }
        },

        // Action to reset the state to its initial value
        reset: () => {
            set(initialState)
            sessionStorage.removeItem('rollState')
        },
    })
