import React from "react";
import * as _Builtin from "../_Builtin";
import { GlobalStyles } from "../_Component/GlobalStyles";

export function SectionLogin({
  as: _Component = _Builtin.Block,
  slotLoginForm,
  info = {},
  // slotForgotPasswordBtn
}) {
  return (
    <_Component
      className="page-wrapper flex-col min-h-100 background-color-blue200"
      tag="div"
    >
      <GlobalStyles />
      <_Builtin.Block
        className="max-width-full flex-center padding-large flex-col background-blue-150"
        tag="div"
      >
        <_Builtin.Image
          className="max-w-10rem margin-bottom margin-xsmall"
          loading="lazy"
          width="auto"
          height="auto"
          alt=""
          src="https://uploads-ssl.webflow.com/65c1f3885f29367896a2beaa/65c25daad138e7bffafb556b_logo.webp"
        />
        <_Builtin.Block
          className="text-align-center text-color-light-grey text-size-small"
          tag="div"
        >
          {"Demo version"}
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block className="padding-global" tag="div">
        <_Builtin.Block className="padding-vertical padding-large" tag="div">
          {slotLoginForm ?? (
            <_Builtin.FormWrapper>
              <_Builtin.FormSuccessMessage>
                <_Builtin.Block tag="div">
                  {"Thank you! Your submission has been received!"}
                </_Builtin.Block>
              </_Builtin.FormSuccessMessage>
              <_Builtin.FormErrorMessage>
                <_Builtin.Block tag="div">
                  {"Oops! Something went wrong while submitting the form."}
                </_Builtin.Block>
              </_Builtin.FormErrorMessage>
            </_Builtin.FormWrapper>
          )}
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}
