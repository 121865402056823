import { sessionModel } from 'entities/session'
import { dialogBaseUrl1, dialogBaseUrl2 } from 'shared/api/ximmersion'
import { createJsonMutation } from 'shared/lib/fetch'
import { zodContract } from 'shared/lib/zod'
import { CreateDialogLineResponseSchema } from './dialog-line.contracts'
import { mapCreateDialogLine } from './dialog-line.lib'
import { CreateDialogLineDto } from './dialog-line.types'

type DialogUrl = 'url1' | 'url2'

export async function createDialogLineMutation(params: {
    dialogLine: CreateDialogLineDto
    dialogUrl?: DialogUrl
}) {
    const baseUrl =
        params.dialogUrl === 'url1' ? dialogBaseUrl1 : dialogBaseUrl2

    return createJsonMutation({
        request: {
            url: baseUrl(`/call-diagen`),
            method: 'POST',
            headers: { ...sessionModel.authorizationHeader({system: 'diagen'})},
            body: JSON.stringify(params.dialogLine),
        },
        response: {
            contract: zodContract(CreateDialogLineResponseSchema),
            mapData: ({ response }) =>
                mapCreateDialogLine(params.dialogLine, response),
        },
    })
}
