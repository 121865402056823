import { sessionModel } from 'entities/session'
import { dialogBaseUrl1, dialogBaseUrl2 } from 'shared/api/ximmersion'
import { createJsonMutation } from 'shared/lib/fetch'
import { zodContract } from 'shared/lib/zod'
import { CreateRollRecordResponseSchema } from './roll-record.contracts'
import { mapRollRecord } from './roll-record.lib'
import { CreateRollRecordDto } from './roll-record.types'

/**
 * @description Mutation function for creating a roll record.
 *
 * @param params - Parameters for creating a roll record.
 * @returns A promise that resolves to the result of the mutation.
 */

type DialogUrl = 'url1' | 'url2'

export async function createRollRecordMutation(params: {
    rollRecord: CreateRollRecordDto
    npcName: string
    preset: string
    dialogUrl?: DialogUrl
}) {
    const baseUrl =
        params.dialogUrl === 'url1' ? dialogBaseUrl1 : dialogBaseUrl2
    return createJsonMutation({
        request: {
            url: baseUrl(`/call-diagen`),
            method: 'POST',
            headers: {
                ...sessionModel.authorizationHeader({ system: 'diagen' }),
            },
            // headers: { ...sessionModel.sessionStore.getState().keys },
            body: JSON.stringify(params.rollRecord),
        },
        response: {
            contract: zodContract(CreateRollRecordResponseSchema),
            mapData: ({ response }) =>
                mapRollRecord(params.rollRecord, response),
        },
    })
}
