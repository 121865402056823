import { z } from 'zod'

export const CreateDialogLineResponseSchema = z.object({
    response: z.string(),
    version: z.number(),
    send_trigger: z.string().optional().nullable(),
    instruction: z.string().optional().nullable(),
})

export const CreateDialogLineDtoSchema = z.object({
    npc_name: z.string(),
    player_name: z.string(),
    temperature: z.number(),
    maxToken: z.number(),
    toxicity: z.number(),
    startState: z.string(),
    endState: z.string(),
    shouldEnd: z.boolean(),
    question: z.string(),
    language: z.string(),
    history: z.array(z.string()).optional(),
})

export const DialogLineSchema = z.object({
    npc_name: z.string(),
    player_name: z.string(),
    temperature: z.number(),
    maxToken: z.number(),
    toxicity: z.number(),
    startState: z.string(),
    endState: z.string(),
    shouldEnd: z.boolean(),
    question: z.string(),
    language: z.string(),
    response: z.string(),
})

export const DialogLinesSchema = z.array(DialogLineSchema)
