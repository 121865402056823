import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'shared/lib/react-query'
import { DialogLine } from './dialog-line.types'
import { createDialogLineMutation } from './dialog-line.api'
import { StoreApi } from 'zustand'
import { dialogModel } from 'entities/dialog'

// TODO: ChatGPT: Considering the structure of the keys and their usage in the file, it seems like there might be a slight redundancy in the dialogLine key. It's essentially the same as the root key in this case.
const keys = {
    root: () => ['dialogLine'],
    dialogLine: () => [...keys.root(), 'dialogLine'],
    createDialogLine: () => [...keys.root(), 'createDialogLine'] as const,
}

// TODO: A voir pour supprimer cette partie (cache non utilisé pour les dialogue)
export const dialogLineService = {
    queryKey: () => keys.dialogLine(),

    getCache: () =>
        queryClient.getQueryData<DialogLine>(dialogLineService.queryKey()),

    setCache: (dialogLine: DialogLine) =>
        queryClient.setQueryData(dialogLineService.queryKey(), dialogLine),

    removeCache: () =>
        queryClient.removeQueries({
            queryKey: dialogLineService.queryKey(),
        }),
}

export function useCreateDialogLineMutation(
    dialogStore?: StoreApi<dialogModel.State>
) {
    return useMutation({
        mutationKey: keys.createDialogLine(),
        mutationFn: createDialogLineMutation,
        onSuccess: (data) => {
            const currentDialogLines = dialogStore?.getState().dialogLines || []
            dialogStore?.setState({
                dialogLines: [...currentDialogLines, data],
            })
        },
    })
}

// TODO: ChatGPT: the useRegenerateDialogLineMutation seems to use the same mutation key as useCreateDialogLineMutation (keys.createDialogLine()).
// TODO: If the intention is to regenerate dialog lines, you might want to consider using a different mutation key to distinguish between creating and regenerating dialog lines.
// TODO: Here's an updated version:
/// export function useRegenerateDialogLineMutation() {
///     return useMutation({
///         mutationKey: keys.regenerateDialogLine(), // Use a different key
///         mutationFn: regenerateDialogLineMutation,
///     })
/// }
// TODO: Ensure that you define the regenerateDialogLineMutation function accordingly.
export function useRegenerateDialogLineMutation() {
    return useMutation({
        mutationKey: keys.createDialogLine(),
        mutationFn: createDialogLineMutation,
    })
}
