import React, { useContext, useState, useLayoutEffect } from "react";
import * as _Builtin from "../_Builtin";
import { ButtonIcon } from "../_Component/ButtonIcon";
import { ButtonText } from "../_Component/ButtonText";
import { DialogContext } from 'pages/dialog/dialog-page.model'

export function PopupDialogExport({
  as: _Component = _Builtin.Block,
  rpCheckboxCsv = {},
  rpClosePopUp = {},
  handleClosePopUp = () => { },
  visibility = true,
}) {
  const { dialogStore } = useContext(DialogContext)
  const [csvChecked, setCSVChecked] = useState(false)
  // const [textChecked, setTextChecked] = useState(false);

  useLayoutEffect(() => {
    if (dialogStore.getState().dialogLines.length > 0) {
      setCSVChecked(true)
      // setTextChecked(true);
    }
  }, [dialogStore.getState().dialogLines])

  const downloadCSV = (dialogLines) => {
    const csv = dialogLines.map(row => `${row.npc_name}|${row.response.trim()}`).join('\n');
    const csvFile = new Blob([csv], { type: 'text/csv' });
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(csvFile);
    downloadLink.download = 'dialog.csv';
    downloadLink.click();
  }

  // const downloadText = (dialogLines) => {
  //   const text = dialogLines.map(row => `${row.npc_name}|${row.response.trim()}`).join('\n');
  //   const textFile = new Blob([text], { type: 'text/plain' });
  //   const downloadLink = document.createElement('a');
  //   downloadLink.href = URL.createObjectURL(textFile);
  //   downloadLink.download = 'dialog.txt';
  //   downloadLink.click();
  // }

  return visibility ? (
    <_Component className="popup-overlay" tag="div">
      <_Builtin.Block
        className="popup-box margin-medium rounded-8 overflow-hidden"
        tag="div"
      >
        <_Builtin.Block
          className="popup-top  background-color-blue200 padding-xsmall"
          tag="div"
          {...rpClosePopUp}
        >
          <_Builtin.Block
            className="flex-center icon_ximm _2 margin-bottom margin-xxsmall"
            tag="div"
          >
            {"\ue908"}
          </_Builtin.Block>
          <_Builtin.Heading className="text-align-center" tag="h2">
            {"Export Dialog"}
          </_Builtin.Heading>
          <_Builtin.Block className="close-popup" tag="div" {...rpClosePopUp}>
            {"\ue91e"}
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block
          className="popup-body padding padding-large background-blue-150 z-index-1"
          tag="div"

        >
          <_Builtin.Paragraph className="text-color-light-grey margin-bottom margin-small padding-horizontal">
            {
              // Explanation for downloading the dialog as a CSV file
              "You can export the dialog as a CSV or text file. This will allow you to use the dialog in other applications."
            }
          </_Builtin.Paragraph>
          <_Builtin.Paragraph className="margin-bottom margin-xsmall">
            {"List of extensions that will be available:"}
          </_Builtin.Paragraph>


          <_Builtin.Grid className="margin-bottom margin-small" tag="div">
            <_Builtin.FormCheckboxWrapper id="w-node-c9494380-7ef0-5b2e-4546-06c8ae4da874-ae4da865">
              <_Builtin.FormCheckboxInput
                type="checkbox"
                name="CSV"
                data-name="CSV"
                checked={csvChecked}
                required={false}
                id="CSV"
                form={{
                  type: "checkbox-input",
                  name: "CSV",
                }}
                inputType="custom"
                customClassName="w-checkbox-input--inputType-custom"
                onChange={() => setCSVChecked(!csvChecked)}
                {...rpCheckboxCsv}
              />
              <_Builtin.FormInlineLabel>{"CSV file"}</_Builtin.FormInlineLabel>
            </_Builtin.FormCheckboxWrapper>
          </_Builtin.Grid>
          {(csvChecked) && (
            <_Builtin.Link
              className="button is-large max-width-full is-secondary"
              button={true}
              block="inline"
              options={{
                href: "#",
              }}
              onClick={(e) => {
                e.preventDefault();
                const dialogLines = dialogStore.getState().dialogLines;
                if (csvChecked) {
                  downloadCSV(dialogLines)
                }
                rpClosePopUp.onClick(e); 
              }}
              >
              <_Builtin.Block className="slot-button" tag="div">
                <ButtonIcon buttonIconText="" />
                <ButtonText buttonText="Get it!"/>
              </_Builtin.Block>
            </_Builtin.Link>
          )}
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  ) : null;
}
