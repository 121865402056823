import {
    PopupDialogExport,
    PopupDialogRegenerate,
    PopupDialogRestart,
    PopupNotification,
    PopupDialogSwap,
    PopupDialogBestPractice,
} from 'devlink'
import { DialogContext } from 'pages/dialog/dialog-page.model'
import React, { useContext, useEffect, useCallback } from 'react';

export function PopupDialogRestartContainer() {
    const { isModalRestartOpen, setIsModalRestartOpen, dialogStore } =
        useContext(DialogContext)


    const handleClosePopup = useCallback(() => {
        setIsModalRestartOpen?.(false);
    }, [setIsModalRestartOpen]);

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup();
            }
        };

        document.addEventListener('keydown', handleEscKey);

        return () => {
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [handleClosePopup]);

    return (
        <PopupDialogRestart
            visibility={isModalRestartOpen}
            rpClosePopup={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalRestartOpen?.(false)
                },
            }}
            rpButtonCancel={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalRestartOpen?.(false)
                },
            }}
            rpButtonConfirm={{
                className: 'button is-primary',
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalRestartOpen?.(false)
                    dialogStore.getState().reset()
                    window.location.reload()
                },
            }}
        />
    )
}

export function PopupDialogRegenerateContainer() {
    const {
        isModalRegenerateOpen,
        setIsModalRegenerateOpen,
        setIsRegenerateTriggered,
    } = useContext(DialogContext)

    const handleClosePopup = useCallback(() => {
        setIsModalRegenerateOpen?.(false);
    }, [setIsModalRegenerateOpen]);

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup();
            }
        };

        document.addEventListener('keydown', handleEscKey);

        return () => {
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [handleClosePopup]);

    return (
        <PopupDialogRegenerate
            visibility={isModalRegenerateOpen}
            rpClosePopup={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalRegenerateOpen?.(false)
                },
            }}
            rpButtonCancel={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalRegenerateOpen?.(false)
                },
            }}
            rpButtonConfirm={{
                className: 'button is-primary',
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalRegenerateOpen?.(false)
                    setIsRegenerateTriggered?.(true)
                },
            }}
        />
    )
}

export function PopupDialogSwapContainer() {
    const {
        isModalSwapOpen,
        setIsModalSwapOpen,
        setIsSwapTriggered,
        dialogStore,
    } = useContext(DialogContext)

    const handleClosePopup = useCallback(() => {
        setIsModalSwapOpen?.(false);
    }, [setIsModalSwapOpen]);

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup();
            }
        };

        document.addEventListener('keydown', handleEscKey);

        return () => {
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [handleClosePopup]);


    return (
        <PopupDialogSwap
            visibility={isModalSwapOpen}
            rpClosePopup={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalSwapOpen?.(false)
                },
            }}
            rpButtonCancel={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalSwapOpen?.(false)
                },
            }}
            rpButtonConfirm={{
                className: 'button is-primary',
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalSwapOpen?.(false)
                    setIsSwapTriggered?.(true)
                    dialogStore.getState().setDialogLines([])
                },
            }}
        />
    )
}

export function PopupDialogExportContainer() {
    const { isModalExportOpen, setIsModalExportOpen } =
        useContext(DialogContext)

        const handleClosePopup = useCallback(() => {
            setIsModalExportOpen?.(false);
        }, [setIsModalExportOpen]);
    
        useEffect(() => {
            const handleEscKey = (event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    handleClosePopup();
                }
            };
            document.addEventListener('keydown', handleEscKey);
            return () => {
                document.removeEventListener('keydown', handleEscKey);
            };
        }, [handleClosePopup]);
        
    return (
        <PopupDialogExport
            visibility={isModalExportOpen}
            rpClosePopUp={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault();
                    setIsModalExportOpen?.(false);
                },
            }}
        // rpButtonExport={{
        //     className: 'button is-disabled',
        // }}
        />
    )
}

export function PopupSaveSentenceContainer() {
    const { isModalSaveSentenceOpen, setIsModalSaveSentenceOpen } =
        useContext(DialogContext)

        const handleClosePopup = useCallback(() => {
            setIsModalSaveSentenceOpen?.(false);
        }, [setIsModalSaveSentenceOpen]);
    
        useEffect(() => {
            const handleEscKey = (event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    handleClosePopup();
                }
            };
    
            document.addEventListener('keydown', handleEscKey);
    
            return () => {
                document.removeEventListener('keydown', handleEscKey);
            };
        }, [handleClosePopup]); 
           
    return (
        <PopupNotification
            visibility={isModalSaveSentenceOpen}
            rpClosePopUp={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalSaveSentenceOpen?.(false)
                },
            }}
            rpInfoText={{
                header: 'Saving Successfully',
                body: 'The sentence has been saved successfully and can be reused later.',
                footer: 'Each sentence is saved separately for each character.'
            }}
        />
    )
}

export function PopupDeleteSentenceContainer() {
    const { isModalDeleteSentenceOpen, setIsModalDeleteSentenceOpen } =
        useContext(DialogContext)

        const handleClosePopup = useCallback(() => {
            setIsModalDeleteSentenceOpen?.(false);
        }, [setIsModalDeleteSentenceOpen]);
    
        useEffect(() => {
            const handleEscKey = (event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    handleClosePopup();
                }
            };
    
            document.addEventListener('keydown', handleEscKey);
    
            return () => {
                document.removeEventListener('keydown', handleEscKey);
            };
        }, [handleClosePopup]); 
            
    return (
        <PopupNotification
            visibility={isModalDeleteSentenceOpen}
            rpClosePopUp={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalDeleteSentenceOpen?.(false)
                },
            }}
            rpInfoText={{
                header: 'Deleted States',
                body: 'All saved states have been deleted.',
                footer: 'Each sentence is saved separately for each character.'
            }}
        />
    )
}

export function PopupDialogBestPracticeContainer() {
    const {
        isModalBestPracticeOpen,
        setIsModalBestPracticeOpen,
        setIsBestPracticeTriggered,
    } = useContext(DialogContext)

    const handleClosePopup = useCallback(() => {
        setIsModalBestPracticeOpen?.(false);
    }, [setIsModalBestPracticeOpen]);

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup();
            }
        };

        document.addEventListener('keydown', handleEscKey);

        return () => {
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [handleClosePopup]);

    return (
        <PopupDialogBestPractice
            visibility={isModalBestPracticeOpen}
            rpClosePopUp={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault();
                    setIsModalBestPracticeOpen?.(false);
                },
            }}
            rpButtonCancel={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalBestPracticeOpen?.(false)
                },
            }}
            rpButtonConfirm={{
                className: 'button is-primary',
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalBestPracticeOpen?.(false)
                    setIsBestPracticeTriggered?.(true)
                },
            }}
        />
    )
}