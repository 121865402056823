import { CreateDialogLineDto, DialogLine } from './dialog-line.types'

export function mapCreateDialogLine(
    request: CreateDialogLineDto,
    response: string
): DialogLine {
    return {
        ...request,
        response: response,
    }
}
