import * as _Builtin from "../_Builtin";
import { ButtonIcon } from "../_Component/ButtonIcon";
import { ButtonText } from "../_Component/ButtonText";

export function PopupIntegrationLockedFeature({
  as: _Component = _Builtin.Block,
  rpClosePopup = {},
  rpButtonConfirm = {},
  visibility = true,
}) {
  return visibility ? (
    <_Component className="popup-overlay" tag="div" {...rpClosePopup}>
      <_Builtin.Block
        className="popup-box margin-medium rounded-8 overflow-hidden"
        tag="div"
      >
        <_Builtin.Block
          className="popup-top background-color-blue200 padding-xsmall"
          tag="div"
        >
          <_Builtin.Block
            className="flex-center icon_ximm _2 margin-bottom margin-xxsmall"
            tag="div"
          >
            {""}
          </_Builtin.Block>
          <_Builtin.Heading className="text-align-center" tag="h2">
            {"Locked feature"}
          </_Builtin.Heading>
          <_Builtin.Block className="close-popup" tag="div" {...rpClosePopup}>
            {""}
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block
          className="popup-body background-blue-150 padding-small"
          tag="div"
        >
          <_Builtin.Paragraph className="text-align-center text-color-light-grey margin-bottom margin-medium">
            {
              "This feature is only available for Premium accounts. Contact us at contact@xandimmersion.com for more information."
            }
          </_Builtin.Paragraph>
          <_Builtin.Link
            className="button is-large is-secondary"
            button={false}
            block="inline"
            options={{
              href: "#",
            }}
            {...rpButtonConfirm}
          >
            <_Builtin.Block className="slot-button" tag="div">
              <ButtonIcon buttonIconText="" />
              <ButtonText buttonText="Got it!" />
            </_Builtin.Block>
          </_Builtin.Link>
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  ) : null;
}