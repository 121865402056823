import React from "react";
import * as _Builtin from "../_Builtin";
import { ButtonIcon } from "./ButtonIcon";
import { ButtonText } from "./ButtonText";

export function Button({
  as: _Component = _Builtin.Link,
  rpButton = {},
  slotButton,
  onClick, // Add onClick prop
  onDrag,
}) {

  return (
    <_Component
      className="button"
      button={false}
      block="inline"
      options={{
        href: "#",
      }}
      onClick={onClick} // Pass onClick prop to the component
      onDrag={onDrag}
      {...rpButton}
    >
      <_Builtin.Block className="slot-button" tag="div">
        {slotButton ?? (
          <>
            <ButtonIcon />
            <ButtonText buttonText={rpButton.text} />
          </>
        )}
      </_Builtin.Block>
    </_Component>
  );
}
