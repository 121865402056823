import { StateCreator } from 'zustand'

export type State = {
    name: string,
    gender: string,
}

export type Actions = {
    setName: (name: string) => void
    setGender: (gender: string) => void
    save: () => void
    load: () => void
    reset: () => void
}

export type PlayerState = State & Actions
export const createPlayer =
    (
        initialState: State
    ): StateCreator<
        PlayerState,
        [['zustand/devtools', never]],
        [],
        PlayerState
    > =>
    (set) => ({
        ...initialState,
        setName: (name: string) =>
            set((state) => ({
                name: name
            })),
        setGender: (gender: string) =>
            set((state) => ({
                gender: gender
            })),
        save: () => 
            set((state) => {
                sessionStorage.setItem('playerState', JSON.stringify(state))
                return state
            }),
        load: () => {
            const savedState = sessionStorage.getItem('dialogState')
            if (savedState) {
                set(JSON.parse(savedState))
            }
        },
        reset: () => {
            set(initialState)
            sessionStorage.removeItem('playerState')
        },
    })
