import { dialogModel } from 'entities/dialog'
import { StoreApi } from 'zustand'

export async function generateNextLine(
    createDialogLine: any,
    dialogStore: StoreApi<dialogModel.State>,
    params: {
        maxToken: number
        toxicity: number
        temperature: number
        shouldEnd: boolean
        language: string
    },
) {
    const state = dialogStore.getState()
    const previousLine = state.dialogLines.length
        ? state.dialogLines.at(-1)
        : null

    const npc =
        state.dialogLines.length % 2 === 0
            ? state.characterStates[0].character
            : state.characterStates[1].character
    const player =
        state.dialogLines.length % 2 === 0
            ? state.characterStates[1].character
            : state.characterStates[0].character

    const playerStartState =
        state.dialogLines.length % 2 === 0
            ? state.characterStates[0].startState
            : state.characterStates[1].startState
    const playerEndState =
        state.dialogLines.length % 2 === 0
            ? state.characterStates[0].endState
            : state.characterStates[1].endState

    // history
    let history = null
    if (state.dialogLines.length >= 3) {
        history = state.dialogLines.slice(-state.dialogLines.length, -1).map((line) => line.response)
    }
    if (state.dialogLines.length === 2) {
        history = state.dialogLines.slice(-2, -1).map((line) => line.response)
    }

    const randomVariance =  Math.random() * 0.2 - 0.1; 
    const randomVarianceTOKEN = Math.random() * 20 - 10; 
    const randomVarianceToxicity =  Math.random() * 0.15; 


    const newTemperature = params.temperature + randomVariance; 
    let newMaxToken =  Math.round(params.maxToken + randomVarianceTOKEN);
    let newToxicity =  Math.round((params.toxicity + randomVarianceToxicity) * 100) / 100;
    const newLineParams = {
        maxToken: Math.max(10, Math.min(200, newMaxToken)),
        toxicity: Math.max(0, Math.min(1, newToxicity)), 
        question: previousLine?.response || '',
        npc_name: npc?.name || '',
        player_name: player?.name || '',
        temperature: Math.max(0, Math.min(1, newTemperature)), 
        startState: playerStartState || '',
        endState: playerEndState || '',
        shouldEnd: params.shouldEnd,
        language: params.language,
        ...(history && { history }),
    }

    if (state.dialogLines.length >= 1) {
        // Add a delay here (waiting for 1 second)
        await wait(params.maxToken*50);
    }

    createDialogLine({ dialogLine: newLineParams })
}

// Function to wait for a specified time
function wait(milliseconds: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(resolve, milliseconds);
    });
  }