import { Emotions } from './emotion.types'

export async function emotionsQuery(): Promise<Emotions> {
    return new Promise((resolve) =>
        setTimeout(
            () =>
                resolve({
                    emotions: [
                    {
                        id: 1,
                        name: 'Happy',
                    },
                    {
                        id: 2,
                        name: 'Sad',
                    },
                    {
                        id: 3,
                        name: 'Angry',
                    },
                    {
                        id: 4,
                        name: 'Surprise',
                    },
                    {
                        id: 5,
                        name: 'Neutral',
                    }
                ],
                speakers: [
                    "Amara",
                    "Cassian",
                    "Leander",
                ]}),
            1000
        )
    )
}
