import { PopupRelease } from 'devlink'
import React, { useContext, useLayoutEffect, useEffect, useCallback } from 'react';
import { ReleaseContext } from 'pages/release-notes/release-notes-page.model'

export function PopupDisplayImgContainer() {
    const { releaseStore, isModalDisplayImgOpen, setIsModalDisplayImgOpen } =
        useContext(ReleaseContext)

        const handleClosePopup = useCallback(() => {
            setIsModalDisplayImgOpen?.(false);
        }, [setIsModalDisplayImgOpen]);
    
        useEffect(() => {
            const handleEscKey = (event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    handleClosePopup();
                }
            };
    
            document.addEventListener('keydown', handleEscKey);
    
            return () => {
                document.removeEventListener('keydown', handleEscKey);
            };
        }, [handleClosePopup]); 

    return (
        <>
            <PopupRelease
                visibility={isModalDisplayImgOpen}
                rpClosePopUp={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        setIsModalDisplayImgOpen?.(false)
                    },
                }}
                src={
                    // get src from store
                    releaseStore.getState().src
                }
                />
        </>
    )
}