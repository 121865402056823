import { z } from 'zod'

export const EmotionSchema = z.object({
    id: z.number(),
    name: z.string(),
})

export const EmotionsSchema = z.object({
    emotions: z.array(EmotionSchema),
    speakers: z.array(z.string()),
})
