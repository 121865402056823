import { z } from 'zod'

export const CreateRollRecordDtoSchema = z.object({
    npc_name: z.string(),
    presets: z.string(),
    player_name: z.string(),
    temperature: z.number(),
    language: z.string(),
    toxicity: z.number(),
    maxToken: z.number(),
    startState: z.string(),
    question: z.string(),
    prompt_name: z.string(),
})

export const CreateRollRecordResponseSchema = z.object({
    response: z.string(),
    version: z.number(),
    send_trigger: z.string().optional().nullable(),
    instruction: z.string().optional().nullable(),
})

export const RollRecordSchema = z.object({
    npc_name: z.string(),
    presets: z.string(),
    player_name: z.string(),
    temperature: z.number(),
    toxicity: z.number(),
    language: z.string(),
    maxToken: z.number(),
    startState: z.string(),
    question: z.string(),
    prompt_name: z.string(),
    response: z.string(),
})

export const RollQuestionSchema = z.object({
    sentence: z.string()
})

export const RollQuestionsSchema = z.array(RollQuestionSchema)
export const RollRecordsSchema = z.array(RollRecordSchema)
