import React from 'react'
import * as _Builtin from '../_Builtin'
import * as _interactions from '../interactions'
import { AnimationTextContainer } from './AnimationTextContainer'
import { AnimationVideoContainer } from './AnimationVideoContainer'

export function SectionTextVideo({
    as: _Component = _Builtin.Section,
}) {

    return (
        <_Component
            className="section"
            grid={{
                type: 'section',
            }}
            tag="section"
        >
            <_Builtin.Block className='padding-horizontal padding-small container-large '>
            <_Builtin.Block
                className="grid-2col"
                tag="div"
            >
                <>
                    <AnimationVideoContainer />
                    <AnimationTextContainer />
                </>
                </_Builtin.Block>
            </_Builtin.Block>
        </_Component>
    )
}