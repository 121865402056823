import type {
    CreateVoiceRecordDto,
    CreateVoiceRecordResponse,
    VoiceRecord,
} from './voice-record.types'

/**
 * @description Maps the data from the API response and DTO to a VoiceRecord object.
 *
 * @param voiceRecordDto - The DTO used for creating a voice record.
 * @param createdVoiceRecord - The response from the API after creating a voice record.
 * @returns Mapped VoiceRecord object.
 */
export function mapVoiceRecord(
    voiceRecordDto: CreateVoiceRecordDto,
    createdVoiceRecord: CreateVoiceRecordResponse
): VoiceRecord {
    return { convergence: voiceRecordDto.convergence, speed: voiceRecordDto.speed, ...createdVoiceRecord }
}
