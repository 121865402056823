import React from "react";
import * as _Builtin from "../_Builtin";
import { GlobalStyles } from "../_Component/GlobalStyles";
import { Sidebar } from "./Sidebar";
import { HeaderLayout } from "../Headers/HeaderLayout";

export function SidebarLayout({
  as: _Component = _Builtin.Block,
  slotSidebarLayout,
  slotSidebarLayoutSidebar,
}) {
  return (
    <_Component className="page-wrapper" tag="div">
      {slotSidebarLayout ?? (
        <>
          <GlobalStyles />
          <_Builtin.Block tag="div">
            {slotSidebarLayoutSidebar ?? <Sidebar />}
          </_Builtin.Block>
          <_Builtin.Block className="slot-header-layout" tag="div">
            {slotSidebarLayout ?? <HeaderLayout />}
          </_Builtin.Block>
        </>
      )}
    </_Component>
  );
}
