import React, { useState } from 'react';
import * as _Builtin from '../_Builtin'; 
import * as _interactions from '../interactions';

export function PauseTags() {
  const [tags, setTags] = useState([
    { label: 's', inputValue: '1' },
    { label: 'ms', inputValue: '200' }
  ]);

  // Make inputs be a maximum of 10 and 900
  const handleInputChange = (index, value) => {
    let newValue = parseInt(value, 10);
    if (isNaN(newValue)) newValue = '';
    if (index === 0 && newValue > 10) {
      newValue = 10;
    } else if (index === 1 && newValue > 900) {
      newValue = 900;
    }
    const newTags = [...tags];
    newTags[index].inputValue = newValue.toString();
    setTags(newTags);
  };

  // Drag and drop functionality
  const handleDragStart = (e, tag) => {
    const input = e.target.querySelector('input'); 
    const tagWithInputValue = tags.find(t => t.label === tag);
    const inputValue = tagWithInputValue ? tagWithInputValue.inputValue : '';
    input.value = inputValue;
    input.selectionStart = input.selectionEnd = input.value.length;
    e.dataTransfer.setData('text/plain', `<pause ${inputValue}${tag}>`);
  };

  return (
    <>
    <_Builtin.Block className='flex-center'>
      {tags.map((tag, index) => (
        <_Builtin.Block key={index} className='light-border margin-right margin-tiny'>
          <_Builtin.Block className='tagStyle' draggable={true} onDragStart={(e) => handleDragStart(e, tag.label)}>
          <_Builtin.Block className='flex-center'>
          <input
            type="text"
            className='input-tags min-h-7rem margin-right margin-tiny z-index-1'
            value={tag.inputValue}
            onChange={(e) => handleInputChange(index, e.target.value)}
            
            />
            {tag.label}
          </_Builtin.Block>
          </_Builtin.Block>
        </_Builtin.Block>
      ))}
    </_Builtin.Block>
    </>
  );
}

