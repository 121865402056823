import { useMutation } from '@tanstack/react-query'
import {
    audioUploadMutation,
    copyNpcMutation,
    createNpcMutation,
    deleteAudioMutation,
    deleteNpcMutation,
    imageUploadMutation,
    textUploadMutation,
    triggerUploadMutation
} from './db.api'

interface NetworkError extends Error {
    response?: string
}

const keys = {
    root: () => ['npc'],
    npcs: () => [...keys.root(), 'npcs'],
    createNpc: () => [...keys.root(), 'createNpc'],
    copyNpc: () => [...keys.root(), 'copyNpc'],
    uploadImage: () => [...keys.root(), 'uploadImage'],
    uploadAudio: () => [...keys.root(), 'uploadAudio'],
    uploadTrigger: () => [...keys.root(), 'uploadTrigger'],
    uploadText: () => [...keys.root(), 'uploadText'],
    deleteNpc: () => [...keys.root(), 'deleteNpc'],
    deleteAudio: () => [...keys.root(), 'deleteAudio'],
}

export function useUploadImage() {
    return useMutation({
        mutationKey: keys.uploadImage(),
        mutationFn: imageUploadMutation,
        onSuccess: (data) => {
        },
        onError: (error: NetworkError) => {
            console.log('Error', error)
        },
    })
}

export function useDeleteNpc() {
    return useMutation({
        mutationKey: keys.deleteNpc(),
        mutationFn: deleteNpcMutation,
        onSuccess: (data) => {
        },
        onError: (error: NetworkError) => {
            console.log('Error', error)
        },
    })
}

export function useUploadNpc() {
    return useMutation({
        mutationKey: keys.createNpc(),
        mutationFn: createNpcMutation,
        onSuccess: (data) => {
        },
        onError: (error: NetworkError) => {
            console.log('Error', error)
        },
    })
}

export function useUploadAudio() {
    return useMutation({
        mutationKey: keys.uploadAudio(),
        mutationFn: audioUploadMutation,
        onSuccess: (data) => {
        },
        onError: (error: NetworkError) => {
            console.log('Error', error)
        },
    })
}

export function useDeleteAudio() {
    return useMutation({
        mutationKey: keys.deleteAudio(),
        mutationFn: deleteAudioMutation,
        onSuccess: (data) => {
        },
        onError: (error: NetworkError) => {
            console.log('Error', error)
        },
    })
}

export function useUploadTrigger() {
    return useMutation({
        mutationKey: keys.uploadTrigger(),
        mutationFn: triggerUploadMutation,
        onSuccess: (data) => {
        },
        onError: (error: NetworkError) => {
            console.log('Error', error)
        },
    })
}

export function useUploadText() {
    return useMutation({
        mutationKey: keys.uploadText(),
        mutationFn: textUploadMutation,
        onSuccess: (data) => {
        },
        onError: (error: NetworkError) => {
            console.log('Error', error)
        },
    })
}

export function useCopyNpc() {
    return useMutation({
        mutationKey: keys.copyNpc(),
        mutationFn: copyNpcMutation,
        onSuccess: (data) => {
            console.log('Success', data)
        },
        onError: (error: NetworkError) => {
            console.log('Error', error)
        },
    })
}
