import React, { useState, useEffect } from 'react';
import * as _Builtin from "../_Builtin";
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export function AnimationVideoContainer({
    as: _Component = _Builtin.Block,
    slotAnimationVideo,
}) {

    const videos = [
        {
            url: "//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FqKQHIFlj06M%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DqKQHIFlj06M&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FqKQHIFlj06M%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube",
            title: "X&Immersion - ANIMATION TOOLS",
        },
        {
            url: "/video/animation.mp4",
            title: "X&Immersion - ANIMATION TOOLS",
        },
        {
            url: "//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FE0TonOl1GX4%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DE0TonOl1GX4&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FE0TonOl1GX4%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube",
            title: "X&Immersion - ANIMATION TOOLS",
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const goToNext = () => {
        const isLastVideo = currentIndex === videos.length - 1;
        const newIndex = isLastVideo ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    const goToPrevious = () => {
        const isFirstVideo = currentIndex === 0;
        const newIndex = isFirstVideo? videos.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowLeft') {
                goToPrevious();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentIndex]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowLeft') {
                goToPrevious();
            } else if (event.key === 'ArrowRight') {
                goToNext();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentIndex]);

    return (
        <_Builtin.Block tag="div">
            <_Builtin.Block className="light-border rounded-8" tag="div">
                <_Builtin.Block className="animation-cards background-color-blue200 rounded-8" tag="div">
                    <_Builtin.Block className="full-cover video-container overflow-hidden" tag="div">
                        {slotAnimationVideo ?? (
                            <_Builtin.Video
                                options={{
                                    height: '134',
                                    width: '250',
                                    title: videos[currentIndex].title,
                                    url: videos[currentIndex].url,
                                }}
                            />
                        )}
                    </_Builtin.Block>
                </_Builtin.Block>
            </_Builtin.Block>
            <_Builtin.Block className="flex-center margin-top margin-tiny" tag="div">
                <FontAwesomeIcon
                    icon={faArrowLeft}
                    onClick={goToPrevious}
                    className="cursor-pointer margin-right margin-small arrow-carousel"
                />
                <FontAwesomeIcon
                    icon={faArrowRight}
                    onClick={goToNext}
                    className='cursor-pointer arrow-carousel '
                />
            </_Builtin.Block>
        </_Builtin.Block>
    );
}