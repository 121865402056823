import * as _Builtin from "../_Builtin";

export function NavbarDivider({ as: _Component = _Builtin.Block }) {
  return (
    <_Component
      className="separator horizontal max-width-full margin-vertical margin-small"
      tag="div"
    />
  );
}
