import { createContext, useState } from 'react'
import { StoreApi, createStore } from 'zustand'
import { DevtoolsOptions, devtools } from 'zustand/middleware'
import { StateCreator } from 'zustand'

export type State = {
    src: string
}

export type Actions = {
    setSrc: (src: string) => void
    save: () => void
    load: () => void
    reset: () => void
}

export type ReleaseState = State & Actions

const initialReleaseState: State = {
    src: ''
}

export const createReleaseSlice =
    (
        initialState: State
    ): StateCreator<
        ReleaseState,
        [['zustand/devtools', never]],
        [],
        ReleaseState
    > =>
    (set) => ({
        ...initialState,
        setSrc: (src: string) =>
            set({ src: src }),
        save: () =>
            set((state) => {
                sessionStorage.setItem('releaseState', JSON.stringify(state))
                return state
            }),
        load: () => {
            const savedState = sessionStorage.getItem('releaseState')
            if (savedState) {
                set(JSON.parse(savedState))
            }
        },
        reset: () => {
            set(initialState)
            sessionStorage.removeItem('releaseState')
        },
    })

const releaseStoreDevtoolsOptions: DevtoolsOptions = {
    name: 'ReleasePage ReleaseStore',
}

export const releaseStore = createStore<ReleaseState>()(
    devtools(
        createReleaseSlice(initialReleaseState),
        releaseStoreDevtoolsOptions
    )
)

type ReleaseContextType = {
    releaseStore: StoreApi<ReleaseState>
    isModalDisplayImgOpen?: boolean
    setIsModalDisplayImgOpen?: (value: boolean) => void
}

export const ReleaseContext = createContext<ReleaseContextType>({
    releaseStore,
    isModalDisplayImgOpen: false,
})

export const ReleaseProvider = ({ children }: { children: React.ReactNode }) => {
    const [isModalDisplayImgOpen, setIsModalDisplayImgOpen] = useState(false)
    
    return (
        <ReleaseContext.Provider
            value={{
                releaseStore,
                isModalDisplayImgOpen: isModalDisplayImgOpen,
                setIsModalDisplayImgOpen: setIsModalDisplayImgOpen,
            }}
        >
            {children}
        </ReleaseContext.Provider>
    )
}

