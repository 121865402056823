import React from "react";
import * as _Builtin from "../_Builtin";

export function ButtonIcon({
  as: _Component = _Builtin.Block,
  buttonIconText = "",
}) {
  return (
    <_Component className="icon_ximm" tag="div">
      {buttonIconText}
    </_Component>
  );
}
