import { HeaderLayout } from 'devlink'
import { characterStore, Team } from 'entities/character/character.model'
import { useState } from 'react'
import { PageHeaderIntegration, PopupLocked, SectionIntegration } from 'widgets/integration'
import { IntegrationProvider } from './integration-page.model'

interface SectionIntegrationProps {
    emotion: string
}

export function IntegrationPage() {

    const initialTeam = {
        name: '',
        is_owner: 0,
        is_admin: 0,
        can_manage_models: 0,
        can_manage_npcs: 0,
        can_manage_datasets: 0,
        can_download_ariel: 0,
        can_download_diagen: 0,
        can_download_gepetto: 0,
    }

    const [selectedTeam, setSelectedTeam] = useState<Team>(characterStore.getState().currentTeam || initialTeam)

    return (
        <>
            <IntegrationProvider>
                <HeaderLayout
                    slotHeader={
                        <PageHeaderIntegration/>
                    }
                    slotHeaderLayout={<SectionIntegration />}
                />
            <PopupLocked />
            </IntegrationProvider>
        </>
    )
}
