import { SectionIntegration, IntegrationUnrealModules, IntegrationUnityModules, Discord } from 'devlink';
import { characterStore } from 'entities/character/character.model';
import { useLayoutEffect, useState } from 'react';

export function SectionIntegrationContainer() {

    return (
        <>
            <SectionIntegration />
            <IntegrationUnrealModulesContainer/>
            <IntegrationUnityModulesContainer/>
            <DiscordContainer/>
        </>
    )
}


export function IntegrationUnrealModulesContainer() {
    const [accessRights, setAccessRights] = useState({
        can_download_ariel: 0,
        can_download_diagen: 0,
        can_download_gepetto: 0
    })
    const downloadFile = (path: string, filename: string) => {
        const link = document.createElement('a');
        link.href = path;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    useLayoutEffect(() => {
        const setAccessRightsFromStore = (state: any) => {
            setAccessRights(
                {
                    can_download_ariel: state.currentTeam?.can_download_ariel || 0,
                    can_download_diagen: state.currentTeam?.can_download_diagen || 0,
                    can_download_gepetto: state.currentTeam?.can_download_gepetto || 0
                }
            )
        };
    
        // Manually set the initial state from the store
        const currentState = characterStore.getState();
        setAccessRightsFromStore(currentState);
    
        // Subscribe to future store changes
        const unsubscribe = characterStore.subscribe((state) => {
            if (state.currentTeam) {
                // console.log("Team", state.currentTeam)
                setAccessRightsFromStore(state);
            }
        });
    
        // Cleanup function to unsubscribe
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <>  
            <IntegrationUnrealModules
                rpButtonUnrealArielDownload52={{
                    label: 'Download Ariel 5.2 Unreal Engine',
                    onClick: (e: Event) => {
                        e.preventDefault()
                        downloadFile('/plugin/[UE5.2] Ariel plugin v1.2.1 - web.zip', 'X&Immersion-Ariel-PlugIn-5.2.zip')
                    }
                }}
                rpButtonUnrealArielDownload53={{
                    label: 'Download Ariel 5.3 Unreal Engine',
                    onClick: (e: Event) => {
                        e.preventDefault()
                        downloadFile('/plugin/[UE5.3] Ariel plugin v1.2.1 - web.zip', 'X&Immersion-Ariel-PlugIn-5.3.zip')
                    }
                }}
                rpButtonUnrealArielDownload54={{
                    label: 'Download Ariel 5.4 Unreal Engine',
                    onClick: (e: Event) => {
                        e.preventDefault()
                        downloadFile('/plugin/[UE5.4] Ariel plugin v1.2.1 - web.zip', 'X&Immersion-Ariel-PlugIn-5.4.zip')
                    }
                }}
                rpButtonUnrealGeppettoDownload52={{
                    label: 'Download Geppetto 5.2 Unreal Engine',
                    onClick: (e: Event) => {
                        e.preventDefault()
                        downloadFile('/plugin/[UE5.2] Geppetto plugin v1.1.0a - web.zip', 'X&Immersion-Geppetto-PlugIn-5.2.zip')
                    }
                }}
                rpButtonUnrealGeppettoDownload53={{
                    label: 'Download Geppetto 5.3 Unreal Engine',
                    onClick: (e: Event) => {
                        e.preventDefault()
                        downloadFile('/plugin/[UE5.3] Geppetto plugin v1.1.0a - web.zip', 'X&Immersion-Geppetto-PlugIn-5.3.zip')
                    }
                }}
                rpButtonUnrealGeppettoDownload54={{
                    label: 'Download Geppetto 5.4 Unreal Engine',
                    onClick: (e: Event) => {
                        e.preventDefault()
                        downloadFile('/plugin/[UE5.4] Geppetto plugin v1.1.0a - web.zip', 'X&Immersion-Geppetto-PlugIn-5.4.zip')
                    }
                }}
                rpUserAccessRights={accessRights}
            />
        </>
    )
}

export function IntegrationUnityModulesContainer() {


    return (
        <>  
            <IntegrationUnityModules
            />
        </>
    )
}
export function DiscordContainer() {
    
    return (
        <>  
            <Discord
            />
        </>
    )
}


