/* eslint-disable react/jsx-pascal-case */
import * as _Builtin from "../_Builtin";

export function SectionClosed({
  as: _Component = _Builtin.Block,
  rpButtonOpen = {},
  rpIcon = "\ue90b",
  title = {},
  subtitle = {},
}) {
  return (
    <_Component
      className="container-large padding-horizontal padding-small"
      tag="div"
    >
      <_Builtin.Block className="closed-card background-color-blue200 rounded-8" tag="div" {...rpButtonOpen}>
          <_Builtin.Block
            className="icon_ximm _2 margin-bottom margin-xxsmall"
            tag="div"
          >
            {rpIcon}
          </_Builtin.Block > 
          <_Builtin.Block
          className="vg-closed-info"
          tag="div">
            <_Builtin.Block className="vg-closed-title">
              {title}
            </_Builtin.Block>
            <_Builtin.Block className="vg-closed-subtitle">
              {subtitle}
            </_Builtin.Block>
          </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}
