import * as _Builtin from '../_Builtin'

export function EmotionTags({tags = []}) {
    // const [tags, setTags] = useState(['angry', 'sad', 'neutral', 'happy', 'surprise']);


    const handleDragStart = (e, tag) => {
        e.dataTransfer.setData('text/plain', `<emotion` + ` ` + tag + `>`);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const tag = e.dataTransfer.getData('text');
        const textField = e.currentTarget;
        const text = textField.value;
        const cursorPosition = textField.selectionStart;
        const leftTagBoundary = text.lastIndexOf('<', cursorPosition);
        const rightTagBoundary = text.indexOf('>', cursorPosition);
        const insertionPoint = (rightTagBoundary === -1 || leftTagBoundary >= rightTagBoundary) ? cursorPosition : rightTagBoundary + 1;
        const newText = text.slice(0, insertionPoint) + tag + text.slice(insertionPoint);
        textField.value = newText;
        textField.dispatchEvent(new Event('input'));
    };

    return (
        <_Builtin.Block className='flex-center'>
            {tags.map((tag, index) => (
                <_Builtin.Block  key={index}
                className='light-border margin-right margin-tiny'>
                    <_Builtin.Block
                        className='tagStyle'
                        draggable={true}
                        onDragStart={(e) => handleDragStart(e, tag)}
                        onDrop={handleDrop}
                    >
                        {tag}
                    </_Builtin.Block>

                </_Builtin.Block>
            ))}
        </_Builtin.Block>
    );
}

