import { useMutation } from '@tanstack/react-query'
import { audioListQuery, charactersQuery, imageListQuery, imageQuery, backgroundQuery } from './character.api'
// import { sessionStore } from 'entities/session/session.model'
import { characterStore } from 'entities/character/character.model'

const keys = {
    root: () => ['character'],
    characters: () => [...keys.root(), 'characters'],
    npcs: () => ['npcs'],
    background: () => ['background'],
    image: () => ['image'],
    images: () => ['images'],
    audios: () => ['audios'],

}
export function useNpcsService() {

    return useMutation({
        mutationKey: keys.npcs(),
        mutationFn: charactersQuery,
        onSuccess: async (data: any) => {
            const sortedNpcs = data.sort((a: any, b: any) => {
                return a.name.localeCompare(b.name);
            })
            characterStore.getState().updateNpcs(sortedNpcs)
        },
        onError: (error) => {
            console.log('error', error)
        },
    })
}

export function useBackgroundService() {
    return useMutation({
        mutationKey: keys.background(),
        mutationFn: charactersQuery,
        onSuccess: async (data: any) => {
            console.log("data", data)
        },
        onError: (error) => {
            console.log('error', error)
        },
    })
}

export function useImageService() {
    return useMutation({
        mutationKey: keys.image(),
        mutationFn: imageQuery,
        onSuccess: async (data: any) => {
            console.log("data", data)
        },
        onError: (error) => {
            console.log('error', error)
        },
    })
}

export function useImageListService() {
    return useMutation({
        mutationKey: keys.images(),
        mutationFn: imageListQuery,
        // TODO : Fix any
        onSuccess: async (data: any) => {
            characterStore.getState().updateImages(data)
        },
        onError: (error) => {
            console.log('error', error)
        },
    })
}

export function useAudioListService(){
    return useMutation({
        mutationKey: keys.audios(),
        mutationFn: audioListQuery,
        onSuccess: async (data: any) => {
            characterStore.getState().updateAudios(data)
        },
        onError: (error) => {
            console.log('error', error)
        },
    })
}