import React from "react";
import * as _Builtin from "../_Builtin";
import * as _interactions from "../interactions";

export function LoaderGenerating({
  as: _Component = _Builtin.Block,
}) {

  return (
    <_Component className="flex gap-4px loadingmessage" tag="div">
      <_Builtin.HtmlEmbed
        className="flex-center"
        data-w-id="deeb99f8-f4cd-1db5-56c6-fd73d81818dd"
        value="%3Csvg%20width%3D%2214%22%20height%3D%2213%22%20viewBox%3D%220%200%2014%2013%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M13.5%206.5C13.5%208.22391%2012.8152%209.87721%2011.5962%2011.0962C10.3772%2012.3152%208.72391%2013%207%2013C5.27609%2013%203.62279%2012.3152%202.40381%2011.0962C1.18482%209.87721%200.5%208.22391%200.5%206.5C0.5%203.9375%201.98813%201.6025%204.29125%200.545628C4.35092%200.518214%204.4154%200.502822%204.48102%200.50033C4.54664%200.497838%204.61211%200.508294%204.67368%200.531103C4.73526%200.553911%204.79174%200.588625%204.8399%200.633261C4.88806%200.677898%204.92696%200.731583%204.95437%200.791253C4.98179%200.850922%204.99718%200.915407%204.99967%200.981025C5.00217%201.04664%204.99171%201.11211%204.9689%201.17369C4.94609%201.23526%204.91138%201.29174%204.86674%201.33991C4.8221%201.38807%204.76842%201.42696%204.70875%201.45438C2.75937%202.34938%201.5%204.32938%201.5%206.5C1.5%207.95869%202.07946%209.35764%203.11091%2010.3891C4.14236%2011.4205%205.54131%2012%207%2012C8.45869%2012%209.85764%2011.4205%2010.8891%2010.3891C11.9205%209.35764%2012.5%207.95869%2012.5%206.5C12.5%204.32938%2011.2406%202.34938%209.29125%201.45438C9.17074%201.39901%209.07716%201.29805%209.0311%201.17369C8.98504%201.04933%208.99026%200.911761%209.04562%200.791253C9.10099%200.670745%209.20196%200.577166%209.32632%200.531103C9.45068%200.485039%209.58824%200.490264%209.70875%200.545628C12.0119%201.6025%2013.5%203.9375%2013.5%206.5Z%22%20fill%3D%22%23EF5E10%22%2F%3E%0A%3C%2Fsvg%3E"
      />
      <_Builtin.Block
        className="text-size-small text-weight-light text-color-orange"
        tag="div"
      >
        {"Getting data..."}
      </_Builtin.Block>
    </_Component>
  );
}
