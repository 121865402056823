import { authBaseUrl, dbBaseUrl, dialogBaseUrl1 } from 'shared/api/ximmersion'
import { createJsonMutation, createJsonQuery } from 'shared/lib/fetch'
import { zodContract } from 'shared/lib/zod'
import { characterStore } from '../character/character.model'
import {
    KeysResponseDtoSchema,
    LoginUserResponseDtoSchema,
    MessageResponseDtoSchema,
    SignupResponseDtoSchema,
    TeamsResponseDtoSchema,
    UserDtoSchema,
} from './session.contracts'
import { mapUser } from './session.lib'
import { authorizationHeader } from './session.model'
import { CreateUserDto, LoginUserDto, UpdateUserDto } from './session.types'

export async function currentUserQuery(signal?: AbortSignal) {
    return createJsonQuery({
        request: {
            url: dialogBaseUrl1('/user'),
            method: 'GET',
            headers: { ...authorizationHeader({system: 'diagen'}) },
        },
        response: {
            contract: zodContract(UserDtoSchema),
            mapData: mapUser,
        },
        abort: signal,
    })
}

export async function createUserMutation(params: { user: CreateUserDto }) {
    return createJsonMutation({
        request: {
            url: dialogBaseUrl1('/users'),
            method: 'POST',
            body: JSON.stringify({ user: params.user }),
        },
        response: {
            contract: zodContract(UserDtoSchema),
            mapData: mapUser,
        },
    })
}

export async function loginUserMutation(params: { user: LoginUserDto }) {
    return createJsonMutation({
        request: {
            url: authBaseUrl('/auth/login'),
            method: 'POST',
            body: JSON.stringify({
                email: params.user.email,
                password: params.user.password,
            }),
        },
        response: {
            contract: zodContract(LoginUserResponseDtoSchema),
        },
    })
}

export async function getTeamsMutation() {
    return createJsonQuery({
        request: {
            url: dbBaseUrl('/team'),
            method: 'GET',
            headers: { ...authorizationHeader({useToken: true}) },
        },
        response: {
            contract: zodContract(TeamsResponseDtoSchema),
        },
    })
}

export async function getKeysMutation() {
    // const team = sessionStorage.getItem("currentTeam") as string;
    const team = characterStore.getState().currentTeam?.name || '';
    return createJsonQuery({
        request: {
            url: dbBaseUrl(`/keys?team=${team}&cache=${Date.now()}`),
            method: 'GET',
            headers: { ...authorizationHeader({useToken: true}) },
        },
        response: {
            contract: zodContract(KeysResponseDtoSchema),
        },
    })
}


export async function forgetPasswordMutation(params: { user: LoginUserDto }) {
    return createJsonMutation({
        request: {
            url: authBaseUrl('/auth/forget-password'),
            method: 'POST',
            body: JSON.stringify({
                email: params.user.email,
            }),
        },
        response: {
            contract: zodContract(MessageResponseDtoSchema),
        },
    })
}

export async function verifyMutation(params: {
    user: LoginUserDto
    code: string
    method: string
    id: string
}) {
    return createJsonMutation({
        request: {
            url: authBaseUrl('/auth/verify'),
            method: 'POST',
            body: JSON.stringify({
                email: params.user.email,
                code: params.code,
                password: params.user.password,
                method: params.method,
                user: params.id,
            }),
        },
        response: {
            contract: zodContract(MessageResponseDtoSchema),
        },
    })
}

export async function resendMutation(params: { user: LoginUserDto, method: string }) {
    return createJsonMutation({
        request: {
            url: authBaseUrl('/auth/resend-confirmation-code'),
            method: 'POST',
            body: JSON.stringify({
                email: params.user.email,
                method: params.method,
            }),
        },
        response: {
            contract: zodContract(MessageResponseDtoSchema),
        },
    })
}

export async function registerMutation(params: {
    user: LoginUserDto
    name: string
    address: string
    birthdate: string
}) {
    return createJsonMutation({
        request: {
            url: authBaseUrl('/auth/register'),
            method: 'POST',
            body: JSON.stringify({
                name: params.name,
                email: params.user.email,
                password: params.user.password,
                address: params.address,
                birthdate: params.birthdate,
            }),
        },
        response: {
            contract: zodContract(SignupResponseDtoSchema),
        },
    })
}

export async function updateUserMutation(params: { user: UpdateUserDto }) {
    return createJsonMutation({
        request: {
            url: dialogBaseUrl1('/user'),
            method: 'PUT',
            headers: { ...authorizationHeader({system: 'diagen'}) },
            body: JSON.stringify({ user: params.user }),
        },
        response: {
            contract: zodContract(UserDtoSchema),
            mapData: mapUser,
        },
    })
}
