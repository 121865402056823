import { characterModel } from 'entities/character'
import { sessionModel } from 'entities/session'
import { dbBaseUrl } from 'shared/api/ximmersion'
import { createJsonQuery } from 'shared/lib/fetch'
import { zodContract } from 'shared/lib/zod'
import { AudioSchema, AudiosSchema, ImageSchema, ImagesSchema, NpcsSchema } from './character.contracts'

export async function charactersQuery() {
    const team = characterModel.characterStore.getState().currentTeam?.name || '';
    return createJsonQuery({
        request: {
            url: dbBaseUrl(`/npcs?team=${team}&cache=${Date.now()}`),
            method: 'GET',
            headers: { ...sessionModel.authorizationHeader({useToken: true}) },
        },
        response: {
            contract: zodContract(NpcsSchema)
        },
    })
}

export async function backgroundQuery() {
    const team = characterModel.characterStore.getState().currentTeam?.name || '';
    return createJsonQuery({
        request: {
            url: dbBaseUrl(`/background?team=${team}`),
            method: 'GET',
            headers: { ...sessionModel.authorizationHeader({useToken: true}) },
        },
        response: {
            contract: zodContract(NpcsSchema)
        },
    })
}

export async function imageQuery(params: { npc: string }) {
    const team = characterModel.characterStore.getState().currentTeam?.name || '';
    return createJsonQuery({
        request: {
            url: dbBaseUrl(`/image?team=${team}&npc=${params.npc}&cache=${Date.now()}`),
            method: 'GET',
            headers: { ...sessionModel.authorizationHeader({useToken: true}) },
        },
        response: {
            contract: zodContract(ImageSchema)
        },
    })
}

export async function imageListQuery() {
    const team = characterModel.characterStore.getState().currentTeam?.name || '';
    return createJsonQuery({
        request: {
            url: dbBaseUrl(`/image/list?team=${team}&cache=${Date.now()}`),
            method: 'GET',
            headers: { ...sessionModel.authorizationHeader({useToken: true}) },
        },
        response: {
            contract: zodContract(ImagesSchema)
        },
    })
}

export async function audioQuery(params: { npc: string }) {
    const team = characterModel.characterStore.getState().currentTeam?.name || '';
    return createJsonQuery({
        request: {
            url: dbBaseUrl(`/audio?team=${team}&npc=${params.npc}&cache=${Date.now()}`),
            method: 'GET',
            headers: { ...sessionModel.authorizationHeader({useToken: true}) },
        },
        response: {
            contract: zodContract(AudioSchema)
        },
    })
}

export async function audioListQuery() {
    const team = characterModel.characterStore.getState().currentTeam?.name || '';
    return createJsonQuery({
        request: {
            url: dbBaseUrl(`/audio/list?team=${team}&cache=${Date.now()}`),
            method: 'GET',
            headers: { ...sessionModel.authorizationHeader({useToken: true}) },
        },
        response: {
            contract: zodContract(AudiosSchema)
        },
    })
}

