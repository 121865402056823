import { sessionModel } from 'entities/session'
import { arielBaseUrl } from 'shared/api/ximmersion'
import { createFormMutation, createJsonQuery } from 'shared/lib/fetch'
import { zodContract } from 'shared/lib/zod'
import { VoiceBatchSchema, VoicesSchema } from './voice.types'

export async function voiceQuery() {
    return createJsonQuery({
        request: {
            url: arielBaseUrl(`/speakers?cache=${Date.now()}`),
            method: 'GET',
            headers: {
                Authorization: `Api-Key ${(window as any).config.ARIEL_API_KEY}`,
            },
        },
        response: {
            contract: zodContract(VoicesSchema),
        },
    })
}

export async function createVoiceBatchMutation(params: {
    file: File | null
    uuid: string | null
}) {
    let body: FormData | string | undefined = undefined
    body = new FormData()
    if (params.uuid) {
        body.append('uuid', params.uuid)
    } else if (params.file) {
        body.append('file', params.file)
    } else {
        return
    }

    return createFormMutation({
        request: {
            url: arielBaseUrl(`/status`),
            method: 'POST',
            headers: {
                ...sessionModel.authorizationHeader({ system: 'ariel' }),
            },
            body: body,
        },
        response: {
            contract: zodContract(VoiceBatchSchema),
        },
    })
}

export async function startVoiceBatchMutation(params: {
    file: File | null
    // max_elements: number | null
    line_start: number | null
    line_end: number | null
}) {
    let body: FormData | string | undefined = undefined
    body = new FormData()
    if (params.file) {
        body.append('file', params.file)
    }
    // if (params.max_elements){
    //     body.append('max_elements', params.max_elements.toString());
    // }
    if (params.line_start) {
        body.append('line_start', params.line_start.toString())
    }
    if (params.line_end) {
        body.append('line_end', params.line_end.toString())
    }

    return createFormMutation({
        request: {
            url: arielBaseUrl(`/batch`),
            method: 'POST',
            headers: {
                ...sessionModel.authorizationHeader({ system: 'ariel' }),
            },
            body: body,
        },
        response: {
            contract: zodContract(VoiceBatchSchema),
        },
    })
}

export async function terminateVoiceBatchMutation(params: {
    file: File | null
    uuid: string | null
}) {
    let body: FormData | string | undefined = undefined
    body = new FormData()
    if (params.uuid) {
        body.append('uuid', params.uuid)
    } else if (params.file) {
        body.append('file', params.file)
    } else {
        return
    }

    return createFormMutation({
        request: {
            url: arielBaseUrl(`/batch`),
            method: 'DELETE',
            headers: {
                ...sessionModel.authorizationHeader({ system: 'ariel' }),
            },
            body: body,
        },
        response: {
            contract: zodContract(VoiceBatchSchema),
        },
    })
}
