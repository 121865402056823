import React from "react";
import * as _Builtin from "../_Builtin";
import { DialogNpcSelectCardForm } from "./DialogNpcSelectCardForm";

export function DialogNpcSelectCard({
  as: _Component = _Builtin.Block,
  rpDialogNpcSelectCardForm = {},
  slotNpcCardForm,
  dialogNpcSelectCardImg = "https://uploads-ssl.webflow.com/65c1f3885f29367896a2beaa/65c3bc8bfd4293cf48f70201_generate-img.webp",
}) {
  return (
    <_Component
      className="light-border rounded-8"
      id="w-node-c8719506-7b47-3944-6d49-76eb5cef14cd-5cef14cd"
      tag="div"
    >
      <_Builtin.Block
        className="npc-cards background-color-blue200 rounded-8"
        tag="div"
      >
        <_Builtin.Block className="npc-card-img" tag="div">
          <_Builtin.Block
            className="npc-card-img-border card-img-border"
            tag="div"
          >
            <_Builtin.Block
              className="background-color-blue200 max-height-full max-width-full rounded-4"
              tag="div"
            />
          </_Builtin.Block>
          <_Builtin.Block className="npc-card-img-slot" tag="div">
            <_Builtin.Image
              className="full-cover"
              id="w-node-c8719506-7b47-3944-6d49-76eb5cef14ce-5cef14cd"
              loading="lazy"
              width="auto"
              height="auto"
              alt=""
              src={dialogNpcSelectCardImg}
            />
            <_Builtin.Block className="fade-img-blue200" tag="div" />
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block className="npc-form padding-small" tag="div">
          {slotNpcCardForm ?? (
            <_Builtin.FormWrapper id="w-node-c8719506-7b47-3944-6d49-76eb5cef14cf-5cef14cd">
              <_Builtin.FormForm
                name="email-form"
                data-name="Email Form"
                method="get"
                id="NPC-form"
                {...rpDialogNpcSelectCardForm}
              >
                <DialogNpcSelectCardForm />
              </_Builtin.FormForm>
              <_Builtin.FormSuccessMessage>
                <_Builtin.Block tag="div">
                  {"Thank you! Your submission has been received!"}
                </_Builtin.Block>
              </_Builtin.FormSuccessMessage>
              <_Builtin.FormErrorMessage>
                <_Builtin.Block tag="div">
                  {"Oops! Something went wrong while submitting the form."}
                </_Builtin.Block>
              </_Builtin.FormErrorMessage>
            </_Builtin.FormWrapper>
          )}
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}
