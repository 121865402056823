import React, { useContext, useState, useLayoutEffect } from "react";
import * as _Builtin from "../_Builtin";
import { ButtonIcon } from "../_Component/ButtonIcon";
import { ButtonText } from "../_Component/ButtonText";
import { RollContext } from "pages/roll/roll-page.model";

export function PopupRollExport({
  as: _Component = _Builtin.Block,
  rpClosePopUp = {},
  rpCheckboxCsv = {},
  rpCheckboxMp3 = {},
  rpCheckboxWave = {},
  rpCheckboxOther = {},
  rpCheckboxText = {},
  visibility = true,
}) {

  const { rollStore } = useContext(RollContext)
  const [csvChecked, setCSVChecked] = useState(false)
  const [textChecked, setTextChecked] = useState(false);

  useLayoutEffect(() => {
    if (rollStore.getState().dialogLines.length > 0) {
      setCSVChecked(true)
      setTextChecked(true);
    }
  }, [rollStore.getState().rollRecords])


const downloadCSV = (rollRecords) => {
    const csv = rollRecords.map(row => `${row.prompt_name}|${row.response.trim()}`).join('\n');
    const csvFile = new Blob([csv], { type: 'text/csv' });
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(csvFile);
    downloadLink.download = 'roll.csv';
    downloadLink.click();
  }


const downloadText = (rollRecords) => {
  const text = rollRecords.map(row => `${row.prompt_name}|${row.response.trim()}`).join('\n');
  const textFile = new Blob([text], { type: 'text/plain' });
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(textFile);
  downloadLink.download = 'roll.txt';
  downloadLink.click();
};

  return visibility ? (
    <_Component className="popup-overlay" tag="div">
      <_Builtin.Block
        className="popup-box margin-medium rounded-8 overflow-hidden"
        tag="div"
      >
        <_Builtin.Block
          className="popup-top background-color-blue200 padding-xsmall"
          tag="div"
          {...rpClosePopUp}
        >
          <_Builtin.Block
            className="flex-center icon_ximm _2 margin-bottom margin-xxsmall"
            tag="div"
          >
            {""}
          </_Builtin.Block>
          <_Builtin.Heading className="text-align-center" tag="h2">
            {"Export Roll"}
          </_Builtin.Heading>
          <_Builtin.Block className="close-popup " tag="div" {...rpClosePopUp}>
            {""}
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block
          className="popup-body background-blue-150 padding-small z-index-1"
          tag="div"
        >
          <_Builtin.Paragraph className="text-color-light-grey margin-bottom margin-small padding-horizontal padding-tiny">
            {
              "You can export the roll as a CSV or text file. This will allow you to use the roll sentences in other applications."
            }
          </_Builtin.Paragraph>
          <_Builtin.Paragraph className="margin-bottom margin-xsmall padding-horizontal padding-tiny">
            {"List of extensions that will be available:"}
          </_Builtin.Paragraph>
          <_Builtin.Grid className="margin-bottom margin-small padding-horizontal padding-tiny" tag="div">
            {/* <_Builtin.FormCheckboxWrapper id="w-node-_49b49137-a163-2836-6d0d-2cfa2dec122f-2dec1220">
              <_Builtin.FormCheckboxInput
                type="checkbox"
                name="MP3"
                data-name="MP3"
                checked={false}
                required={false}
                id="MP3"
                form={{
                  type: "checkbox-input",
                  name: "MP3",
                }}
                inputType="custom"
                customClassName="w-checkbox-input--inputType-custom"
                {...rpCheckboxMp3}
              />
              <_Builtin.FormInlineLabel>{"MP3"}</_Builtin.FormInlineLabel>
            </_Builtin.FormCheckboxWrapper> */}
              <_Builtin.FormCheckboxWrapper id="w-node-c9494380-7ef0-5b2e-4546-06c8ae4da874-ae4da865">
              <_Builtin.FormCheckboxInput
                type="checkbox"
                name="CSV"
                data-name="CSV"
                checked={csvChecked}
                required={false}
                id="CSV"
                form={{
                  type: "checkbox-input",
                  name: "CSV",
                }}
                inputType="custom"
                customClassName="w-checkbox-input--inputType-custom"
                onChange={() => setCSVChecked(!csvChecked)}
                {...rpCheckboxCsv}
              />
              <_Builtin.FormInlineLabel>{"CSV file"}</_Builtin.FormInlineLabel>
            </_Builtin.FormCheckboxWrapper>
            {/* <_Builtin.FormCheckboxWrapper id="w-node-_49b49137-a163-2836-6d0d-2cfa2dec1233-2dec1220">
              <_Builtin.FormCheckboxInput
                type="checkbox"
                name="Wave"
                data-name="Wave"
                required={false}
                checked={false}
                id="Wave"
                form={{
                  type: "checkbox-input",
                  name: "Wave",
                }}
                inputType="custom"
                customClassName="w-checkbox-input--inputType-custom"
                {...rpCheckboxWave}
              />
              <_Builtin.FormInlineLabel>{"Wave"}</_Builtin.FormInlineLabel>
            </_Builtin.FormCheckboxWrapper>
            <_Builtin.FormCheckboxWrapper>
              <_Builtin.FormCheckboxInput
                type="checkbox"
                name="Other"
                data-name="Other"
                required={false}
                checked={false}
                id="Other"
                form={{
                  type: "checkbox-input",
                  name: "Other",
                }}
                inputType="custom"
                customClassName="w-checkbox-input--inputType-custom"
                {...rpCheckboxOther}
              />
              <_Builtin.FormInlineLabel>{"..."}</_Builtin.FormInlineLabel>
            </_Builtin.FormCheckboxWrapper> */}
            <_Builtin.FormCheckboxWrapper>
              <_Builtin.FormCheckboxInput
                type="checkbox"
                name="Text"
                data-name="Text"
                required={false}
                checked={false}
                id="Text"
                form={{
                  type: "checkbox-input",
                  name: "Text",
                }}
                inputType="custom"
                customClassName="w-checkbox-input--inputType-custom"
                onChange={() => setTextChecked(!textChecked)}
                {...rpCheckboxText}
              />
              <_Builtin.FormInlineLabel>{"Text file"}</_Builtin.FormInlineLabel>
            </_Builtin.FormCheckboxWrapper>
          </_Builtin.Grid>
          {(csvChecked || textChecked) && (
            <_Builtin.Link
              className="button is-large max-width-full is-secondary"
              button={true}
              block="inline"
              options={{
                href: "#",
              }}
              {...rpClosePopUp}
              onClick={(e) => {
                e.preventDefault();
                const rollRecords = rollStore.getState().rollRecords;
                if (csvChecked && textChecked) {
                  downloadCSV(rollRecords);
                  downloadText(rollRecords);
                } else if (csvChecked) {
                  downloadCSV(rollRecords);
                } else if (textChecked) {
                  downloadText(rollRecords);
                }
                rpClosePopUp.onClick(e); 
              }}
            >
            <_Builtin.Block className="slot-button" tag="div">
              <ButtonIcon buttonIconText="" />
              <ButtonText buttonText="Get it!" />
            </_Builtin.Block>
          </_Builtin.Link>
          )}
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  ) : null;
}
