import React from "react";
import * as _Builtin from "../_Builtin";

export function DropdownItem({
  as: _Component = _Builtin.DropdownLink,
  dropdownItemTitle = "Paladin",
}) {
  return (
    <_Component
      className="dropdown-links text-color-white"
      options={{
        href: "#",
      }}
    >
      {dropdownItemTitle}
    </_Component>
  );
}
