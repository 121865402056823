import * as _Builtin from '../_Builtin';
import React from 'react';

export function Discord({
    as: _Component = _Builtin.Section,
    rpUserAccessRights,
}) {
    return (
        <>

                    <_Builtin.Block className="container-large padding-horizontal padding-small">
                                <_Builtin.Block
                                    className="light-border rounded-8"
                                    tag="div"
                                >
                                                                    <_Builtin.Block
                                    className="background-color-blue200 rounded-8"
                                    tag="div"
                                >
                                                                <_Builtin.Block tag="div" className='textintegration'>

                                    <_Builtin.Heading className="flex-center">
                                        {"A question?"}
                                    </_Builtin.Heading>
                                    <_Builtin.Paragraph className="flex-center text-color-light-grey margin-bottom margin-xsmall">
                                        {"Our team is here to help you."}
                                    </_Builtin.Paragraph>
                                    <_Builtin.Paragraph className="flex-center padding padding-horizontal padding-small">
                                        <a
                                            href="https://discord.gg/VESpJvkX5u"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="button is-primary"
                                        >
                                            {'Join our Discord and ask every question you want!'}
                                        </a>
                                    </_Builtin.Paragraph>
                                    </_Builtin.Block>
                                    </_Builtin.Block>
                                    </_Builtin.Block>
                    </_Builtin.Block>
        </>
    );
}