import * as _Builtin from '../_Builtin'
import { ButtonIcon } from '../_Component/ButtonIcon'
import { ButtonText } from '../_Component/ButtonText'

export function IntegrationUnrealModules({
    as: _Component = _Builtin.Section,
    rpButtonUnrealArielDownload52,
    rpButtonUnrealArielDownload53,
    rpButtonUnrealArielDownload54,
    rpButtonUnrealGeppettoDownload52,
    rpButtonUnrealGeppettoDownload53,
    rpButtonUnrealGeppettoDownload54,
    rpUserAccessRights,
}) {
    return (
        <_Component
            className="section"
            grid={{
                type: 'section',
            }}
            tag="section"
        >
            <_Builtin.Block
                className='container-large'
                tag="div"
            >
                <_Builtin.Block className='background-color-blue150 rounded-8' tag="div">
                    <_Builtin.Block className="animation-cards flex-center" tag="div">
                        <_Builtin.Block className="textposition" tag="div">
                            <_Builtin.Block className="logoposition" tag="div">
                                <_Builtin.Image
                                    className="logo-cover"
                                    id="w-node-c8719506-7b47-3944-6d49-76eb5cef14ce-5cef14cd"
                                    loading="lazy"
                                    width="auto"
                                    height="auto"
                                    alt=""
                                    src='/images/unrealengine.png'
                                />
                            </_Builtin.Block>
                            <_Builtin.Heading className='flex-center'>
                                {"Integration in Unreal Engine"}
                            </_Builtin.Heading>
                            <_Builtin.Paragraph className="flex-center text-color-light-grey">
                                {"Here you can download the Ariel voice plug-in or the Geppetto animation plug-in directly from this interface for your Unreal software."}
                            </_Builtin.Paragraph>
                        </_Builtin.Block>
                    </_Builtin.Block>

                    <_Builtin.Block className="container-large padding padding-small">
                        <_Builtin.Block className="grid-2col" tag="div">
                            <>
                                <_Builtin.Block tag="div">
                                    <_Builtin.Block
                                        className="light-border rounded-8"
                                        tag="div"
                                    >
                                        <_Builtin.Block
                                            className="animation-cards grid-2col background-color-blue200 rounded-8"
                                            tag="div"
                                        >
                                            <_Builtin.Block
                                                className="logointegration"
                                                tag="div"
                                            >
                                                <_Builtin.Image
                                                    className="logo-cover-integration"
                                                    width="auto"
                                                    height="auto"
                                                    alt=""
                                                    src="/images/ariel.png"
                                                />
                                            </_Builtin.Block>
                                            <_Builtin.Block
                                                className="textintegration"
                                                tag="div"
                                            >
                                                <_Builtin.Paragraph className="flex-center text-weight-semibold text-size-large">
                                                    {'Ariel:'}
                                                </_Builtin.Paragraph>
                                                <_Builtin.Paragraph className="flex-center text-color-light-grey">
                                                    {'Voice Plugin'}
                                                </_Builtin.Paragraph>
                                                <_Builtin.Paragraph className="flex-center margin-top margin-small">
                                                            <a
                                                                href="https://github.com/X-Immersion/Ariel_Unreal_documentation/blob/1.2.1/README.md"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="button is-icon"
                                                            >
                                                                {'Documentation'}
                                                            </a>
                                                        </_Builtin.Paragraph>
                                                {rpUserAccessRights &&
                                                    rpUserAccessRights.can_download_ariel ===
                                                    1 ? (
                                                    <>

                                                        <_Builtin.Block
                                                            className="grid-2col flex-center margin-top margin-small"
                                                            tag="div"
                                                        >
                                                            <_Builtin.Paragraph
                                                                className="cursor-pointer"
                                                                {...rpButtonUnrealArielDownload52}
                                                            >
                                                                {'UE 5.2'}
                                                            </_Builtin.Paragraph>
                                                            <_Builtin.Link
                                                                className="button is-secondary"
                                                                button={false}
                                                                block="inline"
                                                                options={{
                                                                    href: '#',
                                                                }}
                                                                {...rpButtonUnrealArielDownload52}
                                                            >
                                                                <_Builtin.Block
                                                                    className="slot-button"
                                                                    tag="div"
                                                                >
                                                                    <ButtonIcon buttonIconText="" />
                                                                    <ButtonText buttonText="Download" />
                                                                </_Builtin.Block>
                                                            </_Builtin.Link>
                                                        </_Builtin.Block>
                                                        <_Builtin.Block
                                                            className="grid-2col flex-center"
                                                            tag="div"
                                                        >
                                                            <_Builtin.Paragraph
                                                                className="cursor-pointer"
                                                                {...rpButtonUnrealArielDownload53}
                                                            >
                                                                {'UE 5.3'}
                                                            </_Builtin.Paragraph>
                                                            <_Builtin.Link
                                                                className="button is-secondary"
                                                                button={false}
                                                                block="inline"
                                                                options={{
                                                                    href: '#',
                                                                }}
                                                                {...rpButtonUnrealArielDownload53}
                                                            >
                                                                <_Builtin.Block
                                                                    className="slot-button"
                                                                    tag="div"
                                                                >
                                                                    <ButtonIcon buttonIconText="" />
                                                                    <ButtonText buttonText="Download" />
                                                                </_Builtin.Block>
                                                            </_Builtin.Link>
                                                        </_Builtin.Block>
                                                        <_Builtin.Block
                                                            className="grid-2col flex-center"
                                                            tag="div"
                                                        >
                                                            <_Builtin.Paragraph
                                                                className="cursor-pointer"
                                                                {...rpButtonUnrealArielDownload54}
                                                            >
                                                                {'UE 5.4'}
                                                            </_Builtin.Paragraph>
                                                            <_Builtin.Link
                                                                className="button is-secondary"
                                                                button={false}
                                                                block="inline"
                                                                options={{
                                                                    href: '#',
                                                                }}
                                                                {...rpButtonUnrealArielDownload54}
                                                            >
                                                                <_Builtin.Block
                                                                    className="slot-button"
                                                                    tag="div"
                                                                >
                                                                    <ButtonIcon buttonIconText="" />
                                                                    <ButtonText buttonText="Download" />
                                                                </_Builtin.Block>
                                                            </_Builtin.Link>
                                                        </_Builtin.Block>
                                                    </>
                                                ) : (
                                                    <_Builtin.Block className="textposition">
                                                    <_Builtin.Paragraph className="text-color-light-grey">
                                                        {
                                                            'You want to get access to Ariel plugin?' 
                                                        }
                                                    </_Builtin.Paragraph>
                                                    <_Builtin.Paragraph className="text-color-light-grey">
                                                        {
                                                            'Contact us at '
                                                        }
                                                                    <a href="mailto:contact@xandimmersion.com">
                contact@xandimmersion.com
            </a>
            {
                                                            ' and someone from our team will answer to you!'
                                                        }
                                                    </_Builtin.Paragraph>
                                            </_Builtin.Block>
                                                )}
                                            </_Builtin.Block>
                                        </_Builtin.Block>
                                    </_Builtin.Block>
                                </_Builtin.Block>
                                <_Builtin.Block tag="div">
                                    <_Builtin.Block
                                        className="light-border rounded-8"
                                        tag="div"
                                    >
                                        <_Builtin.Block
                                            className="animation-cards grid-2col background-color-blue200 rounded-8"
                                            tag="div"
                                        >
                                            <_Builtin.Block
                                                className="logointegration"
                                                tag="div"
                                            >
                                                <_Builtin.Image
                                                    className="logo-cover-integration"
                                                    width="auto"
                                                    height="auto"
                                                    alt=""
                                                    src="/images/gepetto.png"
                                                />
                                            </_Builtin.Block>
                                            <_Builtin.Block
                                                className="textintegration"
                                                tag="div"
                                            >
                                                <_Builtin.Paragraph className="flex-center text-weight-semibold text-size-large">
                                                    {'Geppetto:'}
                                                </_Builtin.Paragraph>
                                                <_Builtin.Paragraph className="flex-center text-color-light-grey">
                                                    {'Animation Plugin'}
                                                </_Builtin.Paragraph>
                                                <_Builtin.Paragraph className="flex-center margin-top margin-small">
                                                            <a
                                                                href="https://drive.google.com/file/d/1rJnfBVJo0SUFLj-3WvCVPAjbwZTZuCbh/view?usp=drive_link"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="button is-icon"
                                                            >
                                                                {'Documentation'}
                                                            </a>
                                                        </_Builtin.Paragraph>
                                                {rpUserAccessRights &&
                                                    rpUserAccessRights.can_download_gepetto === 1 ? (
                                                    <>
                                                        <_Builtin.Block
                                                            className="grid-2col flex-center margin-top margin-small"
                                                            tag="div"
                                                        >
                                                            <_Builtin.Paragraph
                                                                className="cursor-pointer"
                                                                {...rpButtonUnrealGeppettoDownload52}
                                                            >
                                                                {'UE 5.2'}
                                                            </_Builtin.Paragraph>
                                                            <_Builtin.Link
                                                                className="button is-secondary"
                                                                button={false}
                                                                block="inline"
                                                                options={{
                                                                    href: '#',
                                                                }}
                                                                {...rpButtonUnrealGeppettoDownload52}
                                                            >
                                                                <_Builtin.Block
                                                                    className="slot-button"
                                                                    tag="div"
                                                                >
                                                                    <ButtonIcon buttonIconText="" />
                                                                    <ButtonText buttonText="Download" />
                                                                </_Builtin.Block>
                                                            </_Builtin.Link>
                                                        </_Builtin.Block>
                                                        <_Builtin.Block
                                                            className="grid-2col flex-center"
                                                            tag="div"
                                                        >
                                                            <_Builtin.Paragraph
                                                                className="cursor-pointer"
                                                                {...rpButtonUnrealGeppettoDownload53}
                                                            >
                                                                {'UE 5.3'}
                                                            </_Builtin.Paragraph>
                                                            <_Builtin.Link
                                                                className="button is-secondary"
                                                                button={false}
                                                                block="inline"
                                                                options={{
                                                                    href: '#',
                                                                }}
                                                                {...rpButtonUnrealGeppettoDownload53}
                                                            >
                                                                <_Builtin.Block
                                                                    className="slot-button"
                                                                    tag="div"
                                                                >
                                                                    <ButtonIcon buttonIconText="" />
                                                                    <ButtonText buttonText="Download" />
                                                                </_Builtin.Block>
                                                            </_Builtin.Link>
                                                        </_Builtin.Block>
                                                        <_Builtin.Block
                                                            className="grid-2col flex-center"
                                                            tag="div"
                                                        >
                                                            <_Builtin.Paragraph
                                                                className="cursor-pointer"
                                                                {...rpButtonUnrealGeppettoDownload54}
                                                            >
                                                                {'UE 5.4'}
                                                            </_Builtin.Paragraph>
                                                            <_Builtin.Link
                                                                className="button is-secondary"
                                                                button={false}
                                                                block="inline"
                                                                options={{
                                                                    href: '#',
                                                                }}
                                                                {...rpButtonUnrealGeppettoDownload54}
                                                            >
                                                                <_Builtin.Block
                                                                    className="slot-button"
                                                                    tag="div"
                                                                >
                                                                    <ButtonIcon buttonIconText="" />
                                                                    <ButtonText buttonText="Download" />
                                                                </_Builtin.Block>
                                                            </_Builtin.Link>
                                                        </_Builtin.Block>
                                                    </>) : (
                                                    <_Builtin.Block className="textposition">
                                                    <_Builtin.Paragraph className="text-color-light-grey">
                                                        {
                                                            'You want to get access to Geppetto plugin?' 
                                                        }
                                                    </_Builtin.Paragraph>
                                                    <_Builtin.Paragraph className="text-color-light-grey">
                                                        {
                                                            'Contact us at '
                                                        }
                                                                    <a href="mailto:contact@xandimmersion.com">
                contact@xandimmersion.com
            </a>
            {
                                                            ' and someone from our team will answer to you!'
                                                        }
                                                    </_Builtin.Paragraph>
                                            </_Builtin.Block>
                                                )}
                                            </_Builtin.Block>
                                        </_Builtin.Block>
                                    </_Builtin.Block>
                                </_Builtin.Block>

                            </>
                        </_Builtin.Block>
                    </_Builtin.Block>
                </_Builtin.Block>
            </_Builtin.Block>
        </_Component>
    )
}
