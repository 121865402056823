import { StateCreator } from 'zustand'
import { characterTypes } from 'entities/character'
import { emotionTypes } from 'entities/emotion'
import { voiceRecordTypes } from 'entities/voice-record'
import { voiceTypes } from 'entities/voice'

// Define the state shape
export type State = {
    selectedCharacter?: characterTypes.Npc
    selectedEmotion?: emotionTypes.Emotion
    selectedLanguage?: voiceTypes.Language
    selectedGender?: voiceTypes.Gender
    voiceRecords?: voiceRecordTypes.VoiceRecords
}

// Define the actions that can be performed on the state
export type Actions = {
    setSelectedCharacter: (character: characterTypes.Npc) => void
    setSelectedEmotion: (emotion: emotionTypes.Emotion) => void
    setSelectedLanguage: (language: voiceTypes.Language) => void
    addVoiceRecord: (voiceRecord: voiceRecordTypes.VoiceRecord) => void
    removeVoiceRecord: (index: number) => void
    updateVoiceRecord: (
        index: number,
        voiceRecord: voiceRecordTypes.VoiceRecord
    ) => void
    save: () => void
    load: () => void
    reset: () => void
}

// Combine state and actions into a unified type
export type VoiceState = State & Actions


export const createVoiceSlice =
    (
        initialState: State
    ): StateCreator<
        VoiceState,
        [['zustand/devtools', never]],
        [],
        VoiceState
    > =>
    (set) => ({
        ...initialState,

        // Action to set the selected character
        setSelectedCharacter: (character: characterTypes.Npc) =>
            set({ selectedCharacter: character }),

        // Action to set the selected emotion
        setSelectedEmotion: (emotion: emotionTypes.Emotion) =>
            set({ selectedEmotion: emotion }),

        // Action to set the selected language
        setSelectedLanguage: (language: voiceTypes.Language) =>
            set({ selectedLanguage: language }),

        // Action to add a voice record to the list
        addVoiceRecord: (voiceRecord: voiceRecordTypes.VoiceRecord) =>
            set((state) => ({
                voiceRecords: [...(state.voiceRecords || []), voiceRecord],
            })),

        // Action to remove a voice record from the list
        removeVoiceRecord: (index: number) =>
            set((state) => {
                if (state.voiceRecords?.length === 1) {
                    return {
                        voiceRecords: state.voiceRecords.map((voiceRecord) => ({
                            sentence: '',
                            convergence: 0.5,
                            speed: 1,
                            emotion: undefined,
                            length: undefined,
                            audio: undefined,
                        })),
                    }
                } else {
                    return {
                        voiceRecords: state.voiceRecords?.filter(
                            (_, i) => i !== index
                        ),
                    }
                }
            }),

        // Action to update a voice record in the list
        updateVoiceRecord: (
            index: number,
            voiceRecord: voiceRecordTypes.VoiceRecord
        ) =>
            set((state) => ({
                voiceRecords: state.voiceRecords?.map((record, i) =>
                    i === index ? voiceRecord : record
                ),
            })),

        // Action to save the current state to session storage
        save: () =>
            set((state) => {
                sessionStorage.setItem('voiceState', JSON.stringify(state))
                return state
            }),

        // Action to load the current state from session storage
        load: () => {
            const savedState = sessionStorage.getItem('voiceState')
            if (savedState) {
                set(JSON.parse(savedState))
            }
        },

        // Action to reset the state to its initial value
        reset: () => {
            set(initialState)
            sessionStorage.removeItem('voiceState')
        },
    })

export type FileState = {
    file?: File
    uuid?: string
    status?: string
    message?: string
    url?: string | null
    last_modified?: string | null
    progress?: string
}

export type FileActions = {
    setFile: (file: File) => void
    setUuid: (uuid: string) => void
    setStatus: (status: string) => void
    setUrl: (url: string) => void
    setLastModified: (last_modified: string) => void
    setProgress: (progress: string) => void
    save: () => void
    load: () => void
    reset: () => void
}

export type VoiceFileState = FileState & FileActions

export const createFileSlice =
    ( initialState: FileState): StateCreator<
        VoiceFileState,
        [['zustand/devtools', never]],
        [],
        VoiceFileState
    > =>
    (set) => ({
        ...initialState,

        // Action to set the file
        setFile: (file: File) => set({ file }),

        // Action to set the UUID
        setUuid: (uuid: string) => set({ uuid }),

        // Action to set the status
        setStatus: (status: string) => set({ status }),

        // Action to set the URL
        setUrl: (url: string) => set({ url }),

        // Action to set the last modified date
        setLastModified: (last_modified: string) => set({ last_modified }),

        // Action to set the progress
        setProgress: (progress: string) => set({ progress }),
        
        // Action to save the current state to session storage
        save: () =>
            set((state) => {
                sessionStorage.setItem('fileState', JSON.stringify(state))
                return state
            }),
        
        // Action to load the current state from session storage
        load: () => {
            const savedState = sessionStorage.getItem('fileState')
            if (savedState) {
                set(JSON.parse(savedState))
            }
        },

        // Action to reset the state to its initial value
        reset: () => {
            set(initialState)
            sessionStorage.removeItem('fileState')
        },
    })
