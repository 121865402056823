import * as _Builtin from "../_Builtin";
import { ButtonIcon } from "../_Component/ButtonIcon";
import { ButtonText } from "../_Component/ButtonText";

export function VgAddBloc({
  as: _Component = _Builtin.Block,
  slotAddBlocBtn,
  rpAddBlocButton = {},
}) {
  return (
    <_Component
      className="flex-between-center margin-top margin-xxsmall"
      tag="div"
    >
      <_Builtin.Block className="separator horizontal grow" tag="div" />
      <_Builtin.Link
        className="button is-transparent text-color-blue40"
        button={false}
        block="inline"
        options={{
          href: "#",
        }}
        {...rpAddBlocButton}
      >
        <_Builtin.Block className="slot-button" tag="div">
          <ButtonIcon buttonIconText="" />
          <ButtonText buttonText="Add" />
        </_Builtin.Block>
      </_Builtin.Link>
      <_Builtin.Block className="separator horizontal grow" tag="div" />
    </_Component>
  );
}
