import React from "react";
import * as _Builtin from "../_Builtin";
import { PageHeaderDialog } from "./PageHeaderDialog";
import { SectionDialogNpcSelect } from "../Dialog/SectionDialogNpcSelect";
import { SectionDialogGenerate } from "../Dialog/SectionDialogGenerate";

export function HeaderLayout({
  as: _Component = _Builtin.Block,
  slotHeaderLayout,
  slotHeader,
}) {
  return (
    <_Component className="header-layout" tag="div">
      <_Builtin.Block tag="div">
        {slotHeader ?? <PageHeaderDialog />}
      </_Builtin.Block>
      <_Builtin.Block className="slot-header-layout" tag="div">
        {slotHeaderLayout ?? (
          <>
            <SectionDialogNpcSelect />
            <SectionDialogGenerate />
          </>
        )}
      </_Builtin.Block>
    </_Component>
  );
}
