import { PopupIntegrationLockedFeature } from 'devlink'
import React, { useCallback, useContext, useEffect } from 'react'
import { IntegrationContext } from '../../pages/integration/integration-page.model'

export function PopupLocked() {
    const { isModalLockedOpen, setIsModalLockedOpen } =
        useContext(IntegrationContext)

    const handleClosePopup = useCallback(() => {
        setIsModalLockedOpen?.(false)
    }, [setIsModalLockedOpen])

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup()
            }
        }

        document.addEventListener('keydown', handleEscKey)

        return () => {
            document.removeEventListener('keydown', handleEscKey)
        }
    }, [handleClosePopup])

    return (
        <PopupIntegrationLockedFeature
            visibility={isModalLockedOpen}
            rpButtonConfirm={{ onClick: handleClosePopup }}
            rpClosePopup={{ onClick: handleClosePopup }}
        />
    )
}
