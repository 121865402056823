import { HeaderLayout } from 'devlink'
import { characterStore } from 'entities/character/character.model'
import { Npc } from 'entities/character/character.types'
import { useLayoutEffect, useState } from 'react'
import {
    PageHeaderDialog,
    PopupDeleteSentence,
    PopupDialogBestPractice,
    PopupDialogExport,
    PopupDialogRegenerate,
    PopupDialogRestart,
    PopupDialogSwap,
    PopupSaveSentence,
    SectionDialogGenerate,
    SectionDialogNpcSelect,
} from 'widgets/dialog'
import { DialogProvider } from './dialog-page.model'
interface SectionDialogNpcSelectProps {
    isLoading: boolean
    characters: any
    temperature: number
    toxicity: number
    token: number
    language: string
    setToxicity: (value: number) => void
    setTemperature: (value: number) => void
    setToken: (value: number) => void
    setLanguage: (value: string) => void
}

export function DialogPage() {
    const [characters, setCharacters] = useState<Npc[]>(characterStore.getState().npcs || [])

    const defaultCharacter = {
        id: 0,
        name: 'You as a player',
        background: '',
        defaultStartState: '',
        defaultEndState: '',
        ariel_name: '',
        emotion: [''],
        language: [''],
        trigger_exists: false,
        dataset_exists: false,
        preset: 0,
    }

    const [newCharacters, setNewCharacters] = useState([defaultCharacter])
    // add an empty character at the beginning of the list of characters

    const [temperature, setTemperature] = useState(0.25)
    const [token, setToken] = useState(64)
    const [language, setLanguage] = useState('en')
    const [isGenerating, setIsGenerating] = useState(false)
    const [toxicity, setToxicity] = useState(0.5)
    
    useLayoutEffect(() => {
        characterStore.subscribe((state) => {
            if (state.npcs) {
                setCharacters(state.npcs)
            }
        })
    }, [])

    useLayoutEffect(() => {
        if (characters) {
            setNewCharacters([defaultCharacter, ...characters])
        }
    }, [characters])

    return (
        <DialogProvider>
            <HeaderLayout
                slotHeader={<PageHeaderDialog />}
                slotHeaderLayout={
                    <>
                        <SectionDialogNpcSelect
                            characters={newCharacters}
                            temperature={temperature}
                            token={token}
                            toxicity={toxicity}
                            language={language}
                            setTemperature={setTemperature}
                            setToken={setToken}
                            setLanguage={setLanguage}
                            setToxicity={setToxicity}
                            isGenerating={isGenerating}
                        />
                        <SectionDialogGenerate
                            characters={newCharacters}
                            temperature={temperature}
                            toxicity={toxicity}
                            token={token}
                            language={language}
                            setIsGenerating={setIsGenerating}
                        />
                    </>
                }
            />
            <PopupDialogRestart />
            <PopupDialogRegenerate />
            <PopupDialogSwap />
            <PopupDialogBestPractice/>
            <PopupDialogExport />
            <PopupSaveSentence />
            <PopupDeleteSentence />
        </DialogProvider>
    )
}
