import * as _Builtin from '../_Builtin'

export function SectionIntegration({
    as: _Component = _Builtin.Block,
}) {

    return (
        <_Component
            className="container-large"
            tag="div"
        >

        </_Component>
    )
}
