import { Releases } from 'devlink'
import { useLayoutEffect, useEffect, useState, useContext, useRef } from 'react'
import { set } from 'zod'
import { PopupDisplayImg } from 'widgets/release'
import { ReleaseProvider } from './release-notes-page.model'

export function ReleasesPage() {

    const folders = [
        'v1.1.5',
        // Add more folders as needed - dont forget to add the png files in the public folder
    ]

    return (
        <ReleaseProvider>
            <Releases rpReleases={folders} />
            <PopupDisplayImg />
        </ReleaseProvider>
    )
}
