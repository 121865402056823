import React from "react";
import * as _Builtin from "../_Builtin";

export function ButtonText({
  as: _Component = _Builtin.Block,
  buttonText = "Restart",
}) {
  return (
    <_Component className="text-block" tag="div">
      {buttonText}
    </_Component>
  );
}
