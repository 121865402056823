import { queryClient } from 'shared/lib/react-query'
import { Emotions } from './emotion.types'
import { queryOptions as tsqQueryOptions } from '@tanstack/react-query'
import { emotionsQuery } from './emotion.api'

const keys = {
    root: () => ['emotion'],
    emotions: () => [...keys.root(), 'emotions'],
}

export const emotionsService = {
    queryKey: () => keys.emotions(),

    getCache: () =>
        queryClient.getQueryData<Emotions>(emotionsService.queryKey()),

    setCache: (emotions: Emotions) =>
        queryClient.setQueryData(emotionsService.queryKey(), emotions),

    removeCache: () =>
        queryClient.removeQueries({ queryKey: emotionsService.queryKey() }),

    queryOptions: () => {
        const emotionsKey = emotionsService.queryKey()
        return tsqQueryOptions({
            queryKey: emotionsKey,
            queryFn: async ({ signal }) => emotionsQuery(),
            initialData: () => emotionsService.getCache()!,
            initialDataUpdatedAt: () =>
                queryClient.getQueryState(emotionsKey)?.dataUpdatedAt,
        })
    },

    prefetchQuery: async () =>
        queryClient.prefetchQuery(emotionsService.queryOptions()),

    ensureQueryData: async () =>
        queryClient.ensureQueryData(emotionsService.queryOptions()),
}
