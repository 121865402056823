import { sessionModel } from 'entities/session'
import { Navigate, RouteObject, redirect } from 'react-router-dom'
import { pathKeys } from 'shared/lib/react-router'

export const homePageRoute: RouteObject = {
    path: pathKeys.home(),
    element: <Navigate to={pathKeys.dialog()} />,
    loader: async (args) => {
        if (!sessionModel.hasToken() || !sessionModel.isTokenValid()) {
            return redirect(pathKeys.login())
        }
        return args
    },
}
