import { z } from 'zod'

export const CreateVoiceRecordDtoSchema = z.object({
    sentence: z.string(),
    convergence: z.number(),
    speed: z.number(),
    emotion: z.string().optional(),
})

export const CreateVoiceRecordResponseSchema = z.object({
    sentence: z.string(),
    emotion: z.string().optional(),
    length: z.number(),
    audio: z.string(),
})

export const VoiceRecordSchema = z.object({
    sentence: z.string(),
    convergence: z.number(),
    length: z.number().optional(),
    audio: z.string().optional(),
    speed: z.number(),
    emotion: z.string().optional(),
})

export const VoiceRecordsSchema = z.array(VoiceRecordSchema)
