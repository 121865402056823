import { z } from 'zod';

export const CharacterSchema = z.object({
    id: z.number(),
    name: z.string(),
    image: z.string(),
    voices: z.object({
        neutral: z.string(),
    }),
    defaultStartState: z.string(),
    defaultEndState: z.string(),
})

export const CharactersSchema = z.array(CharacterSchema)

export const NpcSchema = z.object({
    id: z.number(),
    name: z.string(),
    background: z.string(),
    defaultStartState: z.string(),
    defaultEndState: z.string(),
    ariel_name: z.string(),
    emotion: z.array(z.string()).default([]),
    language: z.array(z.string()).default([]),
    trigger_exists: z.boolean(),
    dataset_exists: z.boolean(),
    preset: z.number(),
})

export const NpcsSchema = z.array(NpcSchema).default([]);
export const ImageSchema = z.object({
    name: z.string().optional(),
    image: z.string(),
})

export const ImagesSchema = z.array(ImageSchema)

export const AudioSchema = z.object({
    name: z.string().optional(),
    audio: z.string(),
    description: z.string().optional(),
})

export const AudiosSchema = z.array(AudioSchema)