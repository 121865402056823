import './radio-button.css'

type RadioButtonProps = {
    onClick?: () => void
    checked?: boolean
}
export function RadioButton({ onClick, checked }: RadioButtonProps) {
    return (
        <input
            className="radio-button"
            color="grey"
            type="radio"
            name="radio"
            onClick={onClick}
            checked={checked}
            onChange={() => {}}
        />
    )
}
