import { queryClient } from 'shared/lib/react-query'
import { Voices, VoiceBatch } from './voice.types'
import { queryOptions as tsqQueryOptions } from '@tanstack/react-query'
import {
    voiceQuery,
    createVoiceBatchMutation,
    startVoiceBatchMutation,
    terminateVoiceBatchMutation,
} from './voice.api'
import { voiceModel } from '.'
import { StoreApi } from 'zustand'
import { useMutation } from '@tanstack/react-query'

interface NetworkError extends Error {
    response?: string
}

const keys = {
    root: () => ['voice'],
    speakers: () => [...keys.root(), 'voices'],
}

const keysBatch = {
    root: () => ['status'],
    status: () => [...keys.root(), 'status'],
    createVoiceBatch: () => [...keys.root(), 'createVoiceBatch'],
    startBatchProcess: () => [...keys.root(), 'startBatchProcess'],
    terminateBatchProcess: () => [...keys.root(), 'terminateBatchProcess'],
}

export const voiceBatchService = {
    queryKey: () => keysBatch.status(),

    getCache: () =>
        queryClient.getQueryData<VoiceBatch>(voiceBatchService.queryKey()),

    setCache: (batch: VoiceBatch) =>
        queryClient.setQueryData(voiceBatchService.queryKey(), batch),

    removeCache: () =>
        queryClient.removeQueries({ queryKey: voiceBatchService.queryKey() }),
}

export function useStartBatchGeneration(
    voiceStore?: StoreApi<voiceModel.VoiceFileState>
) {
    return useMutation({
        mutationKey: keysBatch.startBatchProcess(),
        mutationFn: startVoiceBatchMutation,
        onSuccess: (data) => {
            const currentBatchState = voiceStore?.getState() || {}
            voiceStore?.setState({ ...currentBatchState, ...data })
        },
        onError: (error: NetworkError) => {
            const currentBatchState = voiceStore?.getState() || {}
            const errorMessage = error.response
                ? JSON.parse(error.response).detail.error
                : error.message
            voiceStore?.setState({
                ...currentBatchState,
                status: 'error',
                message: errorMessage,
            })
        },
    })
}

export function useTerminateBatchGeneration(
    voiceStore?: StoreApi<voiceModel.VoiceFileState>
) {
    return useMutation({
        mutationKey: keysBatch.terminateBatchProcess(),
        mutationFn: terminateVoiceBatchMutation,
        onSuccess: (data) => {
            const currentBatchState = voiceStore?.getState() || {}
            voiceStore?.setState({ ...currentBatchState, ...data })
        },
        onError: (error: NetworkError) => {
            const currentBatchState = voiceStore?.getState() || {}
            const errorMessage = error.response
                ? JSON.parse(error.response).detail.error
                : error.message
            voiceStore?.setState({
                ...currentBatchState,
                status: 'error',
                message: errorMessage,
            })
        },
    })
}

export function useCreateBatchStatus(
    voiceStore?: StoreApi<voiceModel.VoiceFileState>
) {
    return useMutation({
        mutationKey: keysBatch.createVoiceBatch(),
        mutationFn: createVoiceBatchMutation,
        onSuccess: (data) => {
            const currentBatchState = voiceStore?.getState() || {}
            voiceStore?.setState({ ...currentBatchState, ...data })
        },
        onError: (error: NetworkError) => {
            const currentBatchState = voiceStore?.getState() || {}
            const errorMessage = error.response
                ? JSON.parse(error.response).detail.error
                : error.message
            voiceStore?.setState({
                ...currentBatchState,
                status: 'error',
                message: errorMessage,
            })
        },
    })
}

export const voiceService = {
    queryKey: () => keys.speakers(),

    getCache: () => queryClient.getQueryData<Voices>(voiceService.queryKey()),

    setCache: (characters: Voices) =>
        queryClient.setQueryData(voiceService.queryKey(), characters),

    removeCache: () =>
        queryClient.removeQueries({ queryKey: voiceService.queryKey() }),

    queryOptions: () => {
        const voiceKey = voiceService.queryKey()
        return tsqQueryOptions({
            queryKey: voiceKey,
            queryFn: async ({ signal }) => voiceQuery(),
            initialData: () => voiceService.getCache()!,
            initialDataUpdatedAt: () =>
                queryClient.getQueryState(voiceKey)?.dataUpdatedAt,
        })
    },

    prefetchQuery: async () =>
        queryClient.prefetchQuery(voiceService.queryOptions()),

    ensureQueryData: async () =>
        queryClient.ensureQueryData(voiceService.queryOptions()),
}
