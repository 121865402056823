import React from "react";
import * as _Builtin from "../_Builtin";
import { VgSelectCard } from "../Voice/VgSelectCard";

export function SectionRollGeneration({
  as: _Component = _Builtin.Block,
  slotRollCards,
  slotCharacterRollCard,
}) {
  return (
    <_Component
      className="container-large padding-horizontal padding-small"
      tag="div"
    >
      <_Builtin.Block className="roll-generation-container" tag="div">
        <_Builtin.Block className="vg-left-container" tag="div">
          {slotCharacterRollCard ?? (
            <VgSelectCard characterImage="https://uploads-ssl.webflow.com/65c1f3885f29367896a2beaa/65df44ac5689f844d3699711_Gunwright%201024x1024.png" />
          )}
        </_Builtin.Block>
        <_Builtin.Block  className="rd-low-container background-color-blue200 rounded-bottom" tag="div">
          {slotRollCards}
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}
