import { createContext, useState } from 'react'

type AnimationContextType = {
    isModalLockedOpen: boolean
    setIsModalLockedOpen?: (isModalLockedOpen: boolean) => void
}

export const AnimationContext = createContext<AnimationContextType>({
    isModalLockedOpen: false,
})

export const AnimationProvider = ({
    children,
}: {
    children: React.ReactNode
}) => {
    const [isModalLockedOpen, setIsModalLockedOpen] = useState(false)

    return (
        <AnimationContext.Provider
            value={{
                isModalLockedOpen,
                setIsModalLockedOpen,
            }}
        >
            {children}
        </AnimationContext.Provider>
    )
}
