import { StateCreator, createStore } from 'zustand'
import {
    DevtoolsOptions,
    PersistOptions,
    devtools,
    persist,
} from 'zustand/middleware'

type State = {
    currentTeam?: Team | null
    npcs?: Array<Npc> | null
    background?: Array<any> | null
    images?: Array<any> | null
    audios?: Array<any> | null
}

type Npc = {
    id: number;
    name: string;
    background: string;
    defaultStartState: string;
    defaultEndState: string;
    ariel_name: string;
    emotion: string[];
    language: string[];
    trigger_exists: boolean;
    dataset_exists: boolean;
    preset: number;
}

export type Team = {
    name: string
    is_owner: number
    is_admin: number
    can_manage_models: number
    can_manage_npcs: number
    can_manage_datasets: number
    can_download_ariel: number
    can_download_diagen: number
    can_download_gepetto: number
}

type Actions = {
    updateNpcs: (npcs: Array<Npc> | null) => void
    updateBackground: (background: Array<any> | null) => void
    updateCurrentTeam: (team: Team | null) => void
    updateImages: (images: Array<any> | null) => void
    addImages: (newImages: Array<any>) => void
    clearImages: () => void
    updateAudios: (audios: Array<any> | null) => void
    addAudios: (newAudios: Array<any>) => void
    clearAudios: () => void
}

type CharacterState = State & Actions

const createSessionSlice: StateCreator<
    CharacterState,
    [['zustand/devtools', never], ['zustand/persist', unknown]],
    [],
    CharacterState
> = (set) => ({
    npcs: [],
    updateNpcs: (npcs: Array<any> | null) =>
        set({ npcs: npcs || null }, false, 'updateNpcs'),
    background: [],
    updateBackground: (background: Array<any> | null) =>
        set({ background: background || null }, false, 'updateBackground'),
    currentTeam: null,
    updateCurrentTeam: (team: Team | null) =>
        set({ currentTeam: team || null }, false, 'updateCurrentTeam'),
    images: [],
    updateImages: (images: Array<any> | null) =>
        set({ images: images || null }, false, 'updateImages'),
    addImages: (newImages: Array<any>) =>
        set((state) => ({
            images: state.images ? [...state.images, ...newImages] : newImages
        }), false, 'addImages'),
    clearImages: () =>
        set({ images: [] }, false, 'clearImages'),
    audios: [],
    updateAudios: (audios: Array<any> | null) =>
        set({ audios: audios || null }, false, 'updateAudios'),
    addAudios: (newAudios: Array<any>) =>
        set((state) => ({
            audios: state.audios ? [...state.audios, ...newAudios] : newAudios
        }), false, 'addAudios'),
    clearAudios: () =>
        set({ audios: [] }, false, 'clearAudios'),
})

const persistOptions: PersistOptions<CharacterState> = { name: 'session' }
const devtoolsOptions: DevtoolsOptions = { name: 'SessionStore' }

export const characterStore = createStore<CharacterState>()(
    devtools(persist(createSessionSlice, persistOptions), devtoolsOptions)
)