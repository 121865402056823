import { StateCreator, createStore } from 'zustand';
import { DevtoolsOptions, PersistOptions, devtools, persist } from 'zustand/middleware';

type SystemKeys = 'ariel' | 'diagen' | 'gepetto';
type Token = string;
type Key = {
    key: string;
    max_requests: number;
    batch: number;
};

type Keys = {
    ariel: Key;
    diagen: Key;
    gepetto: Key;
};
type State = {
    token: Token | null
    image: File | null
    expiresIn?: number
    teams?: Array<any> | null;
    keys?: Keys | null;
}

type Character = {
    v: string;
    t: string;
    playerName: string;
    background: string;
    image: File | null;
    audio: File | null;
    trigger: File | null;
    text: File | null;
}

type Actions = {
    updateToken: (token: Token | null) => void
    updateImage: (image: File | null) => void
    updateTeams: (teams: Array<any> | null) => void;
    updateKeys: (keys: Keys | null) => void;
    updateExpiresIn: (expiresIn: number) => void;
}

type SessionState = State & Actions;

const createSessionSlice: StateCreator<
    SessionState,
    [['zustand/devtools', never], ['zustand/persist', unknown]],
    [],
    SessionState
> = (set) => ({
    token: null,
    image: null,
    teams: null,
    keys: {
        ariel: { key: '', max_requests: 0, batch: 0 },
        diagen: { key: '', max_requests: 0, batch: 0 },
        gepetto: { key: '', max_requests: 0, batch: 0 },
    },
    expiresIn: 0,
    updateToken: (token: Token | null) =>
        set({ token: token || null }, false, 'updateToken'),
    updateImage: (image: File | null) => 
        set({ image: image || null }, false, 'updateImage'),
    updateTeams: (teams: Array<any> | null) => set({ teams: teams || null }, false, 'updateTeams'),
    updateKeys: (keys: Keys | null) => set({ keys: keys || null }, false, 'updateKeys'),
    updateExpiresIn: (expiresIn: number) => set({ expiresIn: expiresIn }, false, 'updateExpiresIn'),
})
const persistOptions: PersistOptions<SessionState> = {
    name: 'session',
    getStorage: () => sessionStorage, // Ensure using sessionStorage instead of localStorage
};

const devtoolsOptions: DevtoolsOptions = { name: 'SessionStore' };

export const sessionStore = createStore<SessionState>()(
    devtools(persist(createSessionSlice, persistOptions), devtoolsOptions)
);

export const hasToken = () => Boolean(sessionStore.getState().token);

export const isTokenValid = () => {
    const expiresIn = sessionStore.getState().expiresIn;
    if (expiresIn) {
        return Date.now() < expiresIn;
    }
    return false;
};

export function authorizationHeader({ useToken = false, system = 'ariel' }: { useToken?: boolean; system?: SystemKeys }) {
    const keys = sessionStore.getState().keys;
    if (useToken === false) {
        if (!keys) {
            return {};
        } else {
            return {
                Authorization: `Api-Key ${keys[system].key}`,
            };
        }
    } else {
        return {
            Authorization: `Bearer ${sessionStore.getState().token}`,
        };
    }
}

// // Example usage
// const header = authorizationHeader({ useToken: true, system: 'ariel' });
// console.log(header);
