import { createContext, useState } from 'react'
import { StoreApi } from 'zustand'
import { voiceModel } from 'entities/voice'
import { voiceStore, fileStore } from './voice-page.model'

type VoiceContextType = {
    voiceStore: StoreApi<voiceModel.VoiceState>
    fileStore: StoreApi<voiceModel.VoiceFileState>
    isModalRestartOpen: boolean
    setIsModalRestartOpen?: (value: boolean) => void
    isModalExportOpen?: boolean
    setIsModalExportOpen?: (value: boolean) => void
}

export const VoiceContext = createContext<VoiceContextType>({
    voiceStore,
    fileStore,
    isModalRestartOpen: false,
    isModalExportOpen: false,
})

export const VoiceProvider = ({ children }: { children: React.ReactNode }) => {
    const [isModalRestartOpen, setIsModalRestartOpen] = useState(false)
    const [isModalExportOpen, setIsModalExportOpen] = useState(false)

    return (
        <VoiceContext.Provider
            value={{
                voiceStore,
                fileStore,
                isModalRestartOpen,
                setIsModalRestartOpen,
                isModalExportOpen,
                setIsModalExportOpen,
            }}
        >
            {children}
        </VoiceContext.Provider>
    )
}
