import { z } from 'zod'

export const VoiceBatchSchema = z.object({
    uuid: z.string(),
    status: z.string(),
    progress: z.string(),
    message: z.string(),
    url: z.string().nullable(),
    last_modified: z.string().nullable(),
})

export const VoiceBatchesSchema = z.array(VoiceBatchSchema)

export const VoiceSchema = z.object({
    id: z.number(),
    name: z.string(),
    language: z.array(z.string()),
    gender: z.string(),
})

export const VoicesSchema = z.array(VoiceSchema)
export const languageSchema = z.object(
    {
        id: z.number(),
        name: z.string(),
    }
)
export const languagesSchema = z.array(languageSchema)
export const genderSchema = z.object(
    {
        id: z.number(),
        name: z.string(),
    }
)
export const gendersSchema = z.array(genderSchema)

export type Language = z.infer<typeof languageSchema>
export type Languages = z.infer<typeof languagesSchema>
export type Gender = z.infer<typeof genderSchema>
export type Genders = z.infer<typeof gendersSchema>
export type Voice = z.infer<typeof VoiceSchema>
export type Voices = z.infer<typeof VoicesSchema>
export type VoiceBatch = z.infer<typeof VoiceBatchSchema>
export type VoiceBatches = z.infer<typeof VoiceBatchesSchema>
