import React from 'react'
import { SectionTextVideo, SectionAnimationButtonDialog } from 'devlink'

export function SectionAnimationContainer() {

    return (
        <>
            <SectionTextVideo />
            <SectionAnimationButtonDialog />
        </>
    )
}


