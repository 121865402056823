import React, { useState } from "react";
import * as _Builtin from "../_Builtin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

export function PopupRelease({
  as: _Component = _Builtin.Block,
  visibility = true,
  rpClosePopUp = {},
  src = {},
}) {
  const srcInfo = src.split("/")[1];
  //   const [isOpen, setIsOpen] = useState(false);
  //   const [isZoomed, setIsZoomed] = useState(false);

  //   const toggleZoom = () => {
  //     setIsZoomed(!isZoomed);
  //   };

  //   const handleMouseMove = (e) => {
  //     const {left, top, width, height} = e.target.getBoundingClientRect();
  //     const x = ((e.pageX - left) / width) * 100;
  //     const y = ((e.pageY - top) / height) * 100;
  //     e.target.style.transformOrigin = `${x}% ${y}%`;
  // };

  return visibility ? (
    <_Component className="popup-overlay" tag="div" {...rpClosePopUp}>
      <_Builtin.Block
        className="release-container background-color-blue150"
        tag="div"
      >
        <_Builtin.Block
          className="flex-center padding padding-medium background-color-blue200"
          tag="div"
        >
          <_Builtin.Heading className="text-align-center " tag="h2">
            <FontAwesomeIcon className="margin-right margin-tiny" icon={faCircleInfo} />
            {`Release ${src.split("/")[1]} - ${src.split("/")[2].split(".png")[0]}`}
          </_Builtin.Heading>
          <_Builtin.Block className="close-popup" tag="div" {...rpClosePopUp}>
            {""}
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block className="release-img-container background-color-blue200" tag="div">
          <_Builtin.Image
            className="release-img"
            // onClick={() => setIsZoomed(!isZoomed)}
            // onMouseLeave={isZoomed ? toggleZoom : null}
            // onMouseMove={isZoomed ? handleMouseMove : null}
            loading="lazy"
            width="auto"
            height="auto"
            alt=""
            src={src}
          />
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  ) : null;
}