import React from "react";
import * as _Builtin from "../_Builtin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

export function AnimationOptions({
  as: _Component = _Builtin.Block,
}) {
  return (
    <_Builtin.Block className="animationbtn-wrapper" tag="div">
      <_Builtin.Block className="button is-large is-secondary w-inline-block" tag="div">
        <FontAwesomeIcon icon={faCircleCheck} size="1x" />
        <_Builtin.Heading className="text-size-regular margin-left margin-xxsmall" tag="h2">
          {"Generate smooth facial animation"}
        </_Builtin.Heading>
      </_Builtin.Block>
      <_Builtin.Block className="button is-large is-secondary w-inline-block" tag="div">
      <FontAwesomeIcon icon={faCircleCheck} size="1x" />
      <_Builtin.Heading className="text-size-regular margin-left margin-xxsmall" tag="h2">
          {"Automatically lip sync with audio"}
        </_Builtin.Heading>
      </_Builtin.Block>
      <_Builtin.Block className="button is-large is-secondary w-inline-block" tag="div">
        <FontAwesomeIcon icon={faCircleCheck} size="1x" />
        <_Builtin.Heading className="text-size-regular margin-left margin-xxsmall" tag="h2">
          {"Wide range of emotions"}
        </_Builtin.Heading>
    </_Builtin.Block>
    </_Builtin.Block>
  );
}