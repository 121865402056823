import React from "react";
import * as _Builtin from "../_Builtin";

export function PopupNotification({
  as: _Component = _Builtin.Block,
  visibility = true,
  rpClosePopUp = {},
  rpInfoText = {},
  rpIcon = "\ue90b",
}) {
  return visibility ? (
    <_Component className="popup-overlay-best" tag="div" {...rpClosePopUp}>
      <_Builtin.Block
        className="popup-box margin-medium rounded-8 overflow-hidden"
        tag="div"
      >
        <_Builtin.Block
          className="popup-top background-color-blue200 padding-xsmall"
          tag="div"
        >
          <_Builtin.Block
            className="flex-center icon_ximm _2 margin-bottom margin-xxsmall"
            tag="div"
          >
            {rpIcon}
          </_Builtin.Block>
          <_Builtin.Heading className="text-align-center" tag="h2">
            {rpInfoText.header || "Notification"}
          </_Builtin.Heading>
          <_Builtin.Block className="close-popup" tag="div" {...rpClosePopUp}>
            {"\ue91e"}
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block
          className="popup-body background-blue-150 padding-small"
          tag="div"
        >
          <_Builtin.Paragraph className="text-align-center text-color-light-grey margin-bottom margin-xxsmall">
            {rpInfoText.body || ""}
          </_Builtin.Paragraph>
          <_Builtin.Block
            className="text-weight-bold text-align-center text-color-light-grey margin-bottom margin-small"
            tag="div"
          >
            {rpInfoText.footer || ""}
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  ) : null;
}
