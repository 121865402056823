import { dialogModel } from 'entities/dialog'
import { StoreApi } from 'zustand'

  export function regenerateOneLine(
    regenerateDialogLine: any,
    dialogStore: StoreApi<dialogModel.State>,
    params: {
        lineIndex: number
        toxicity: number
        maxToken: number
        temperature: number
        shouldEnd: boolean
        language: string
    }
) {
    const state = dialogStore.getState()
    const previousLine =
        params.lineIndex > 0 ? state.dialogLines.at(params.lineIndex - 1) : null

    const npc =
        params.lineIndex % 2 === 0
            ? state.characterStates[0].character
            : state.characterStates[1].character
    const player =
        params.lineIndex % 2 === 0
            ? state.characterStates[1].character
            : state.characterStates[0].character

    const playerStartState =
        params.lineIndex % 2 === 0
            ? state.characterStates[0].startState
            : state.characterStates[1].startState
    const playerEndState =
        params.lineIndex % 2 === 0
            ? state.characterStates[0].endState
            : state.characterStates[1].endState

    if (state.dialogLines.length < params.lineIndex) {
        return
    }

    const newLineParams = {
        maxToken: Math.round(params.maxToken),
        question: previousLine?.response || '',
        npc_name: npc?.name || '',
        player_name: player?.name || '',
        temperature: Math.max(0, Math.min(1, params.temperature)), 
        toxicity: Math.max(0, Math.min(1, params.toxicity)), 
        startState: playerStartState || '',
        endState: playerEndState || '',
        shouldEnd: params.shouldEnd,
        language: params.language
        

    }

    regenerateDialogLine({
        dialogLine: newLineParams,
    })


}
