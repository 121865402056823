import React from "react";
import * as _Builtin from "../_Builtin";
import { VgSelectCard } from "./VgSelectCard";
import { VgGenerateLine } from "./VgGenerateLine";
import { VgAddBloc } from "./VgAddBloc";

export function SectionVoiceGeneration({
  as: _Component = _Builtin.Block,
  rpDropdownCharacter = {},
  rpDropdownEmotion = {},
  slotVoiceCards,
  rpVgDeleteLine = {},
  rpButtonPlaySample = {},
  characterImage = "https://uploads-ssl.webflow.com/65c1f3885f29367896a2beaa/65c3bc8bfd4293cf48f70201_generate-img.webp",
  slotCharacterVoiceCard,
}) {
  return (
    <_Component
      className="container-large padding-horizontal padding-small"
      tag="div"
    >
      <_Builtin.Block className="voice-generation-container" tag="div">
        <_Builtin.Block className="vg-left-container" tag="div">
          {slotCharacterVoiceCard ?? (
            <VgSelectCard characterImage="https://uploads-ssl.webflow.com/65c1f3885f29367896a2beaa/65df44ac5689f844d3699711_Gunwright%201024x1024.png" />
          )}
        </_Builtin.Block>
        <_Builtin.Block className="vg-right-container" tag="div">
          {slotVoiceCards ?? (
            <>
              <VgGenerateLine rpVgDeleteLine={rpVgDeleteLine} />
              <VgAddBloc />
            </>
          )}
        </_Builtin.Block>
        <_Builtin.HtmlEmbed
          className="html-embed"
          value="%3Cstyle%3E%0A.textarea%20%7B%0A%20%20resize%3A%20none%3B%0A%7D%0A%3C%2Fstyle%3E%0A%0A%3Cscript%3E%0A%2F%2F%20Find%20the%20span%20element%20with%20the%20class%20'text-input'%0Avar%20spanElement%20%3D%20document.querySelector('.text-input')%3B%0A%0A%2F%2F%20Add%20attributes%20to%20the%20span%20element%0AspanElement.classList.add('textarea')%3B%0AspanElement.setAttribute('role'%2C%20'textbox')%3B%0AspanElement.setAttribute('contenteditable'%2C%20'')%3B%0A%0A%3C%2Fscript%3E"
        />
      </_Builtin.Block>
    </_Component>
  );
}
