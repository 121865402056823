import React from "react";
import * as _Builtin from "../_Builtin";


export function PageHeaderAnimation({
  as: _Component = _Builtin.Block,
}) {
  return (
    <_Component className="header" tag="div">
    <_Builtin.Block
      className="background-color-blue200 padding-small"
      tag="div"
    >
      <_Builtin.Block className="header-container" tag="div">
        <_Builtin.Block className="header-left" tag="div">
          <_Builtin.Heading tag="h1">{"Facial Animation"}</_Builtin.Heading>
        </_Builtin.Block>
      </_Builtin.Block>
    </_Builtin.Block>
  </_Component>
  );
}
