import React from "react";
import * as _Builtin from "../_Builtin";

export function AnimationTextContainer({
    as: _Component = _Builtin.Block,
}) {

    return (
        <_Builtin.Block
            tag="div"
        >
            <_Builtin.Block
                className="light-border rounded-8"
                tag="div"
            >
                <_Builtin.Block className="animation-cards background-color-blue200 rounded-8" tag="div">
                    <_Builtin.Block className="textposition" tag="div">
                        <_Builtin.Paragraph className="text-color-light-grey">
                            {"Our tool utilizes advanced algorithms to automatically generate facial animations and lip sync that match the rhythm and tone of your character's speech, enabling you to concentrate on other aspects of your project."}
                        </_Builtin.Paragraph>
                        <_Builtin.Paragraph className="text-color-light-grey">
                            {"We can adjust the emotional range to suit your characters and their rigs. These adjustments can then be refined by animators, allowing you to maintain creative control and save time."}
                        </_Builtin.Paragraph>
                        <_Builtin.Paragraph className="text-color-light-white">
                            {"Easy to integrate:"}
                        </_Builtin.Paragraph>
                        <_Builtin.Block className="logoposition" tag="div">
                            <_Builtin.Image
                                className="logo-cover "
                                id="w-node-c8719506-7b47-3944-6d49-76eb5cef14ce-5cef14cd"
                                loading="lazy"
                                width="auto"
                                height="auto"
                                alt=""
                                src='/images/unrealengine.png'
                            />
                            <_Builtin.Image
                                className="logo-cover "
                                id="w-node-c8719506-7b47-3944-6d49-76eb5cef14ce-5cef14cd"
                                loading="lazy"
                                width="auto"
                                height="auto"
                                alt=""
                                src='/images/unity.png'
                            />
                            <_Builtin.Image
                                className="logo-cover "
                                id="w-node-c8719506-7b47-3944-6d49-76eb5cef14ce-5cef14cd"
                                loading="lazy"
                                width="auto"
                                height="auto"
                                alt=""
                                src='/images/restapi.png'
                            />
                            <_Builtin.Image
                                className="logo-cover "
                                id="w-node-c8719506-7b47-3944-6d49-76eb5cef14ce-5cef14cd"
                                loading="lazy"
                                width="auto"
                                height="auto"
                                alt=""
                                src='/images/other.png'
                            />
                        </_Builtin.Block>
                    </_Builtin.Block>
                </_Builtin.Block>
            </_Builtin.Block>
        </_Builtin.Block>
    );
}