import { dialogModel } from 'entities/dialog'
import { playerModel } from 'entities/player'
import { createContext, useState } from 'react'
import { StoreApi, createStore } from 'zustand'
import { DevtoolsOptions, devtools } from 'zustand/middleware'

const initialDialogState: dialogModel.State = {
    characterStates: [
        {
            character: undefined,
            startState: '',
            endState: '',
        },
        {
            character: undefined,
            startState: '',
            endState: '',
        },
    ],
    dialogLines: [],
    startEndIndex: -1,
}
const initialPlayerState: playerModel.State = {
    name: '',
    gender: 'female',
}
const dialogStoreDevtoolsOptions: DevtoolsOptions = {
    name: 'DialogPage DialogStore',
}

const playerStoreDevtoolsOptions: DevtoolsOptions = {
    name: 'DialogPage DialogStore',
}

export const dialogStore = createStore<dialogModel.DialogState>()(
    devtools(
        dialogModel.createDialogSlice(initialDialogState),
        dialogStoreDevtoolsOptions
    )
)
export const playerStore = createStore<playerModel.PlayerState>()(
    devtools(
        playerModel.createPlayer(initialPlayerState),
        playerStoreDevtoolsOptions
    )
)

type DialogContextType = {
    dialogStore: StoreApi<dialogModel.DialogState>
    playerStore: StoreApi<playerModel.PlayerState>
    isModalRestartOpen: boolean
    setIsModalRestartOpen?: (value: boolean) => void
    isModalRegenerateOpen: boolean
    setIsModalRegenerateOpen?: (value: boolean) => void
    isModalExportOpen?: boolean
    setIsModalExportOpen?: (value: boolean) => void
    isRegenerateTriggered: boolean
    setIsRegenerateTriggered?: (value: boolean) => void
    isModalSaveSentenceOpen: boolean
    setIsModalSaveSentenceOpen?: (value: boolean) => void
    isModalDeleteSentenceOpen?: boolean
    setIsModalDeleteSentenceOpen?: (value: boolean) => void
    isSwapTriggered?: boolean
    setIsSwapTriggered?: (value: boolean) => void
    isModalSwapOpen?: boolean
    setIsModalSwapOpen?: (value: boolean) => void
    isModalBestPracticeOpen?: boolean
    setIsModalBestPracticeOpen?: (value: boolean) => void
    isBestPracticeTriggered?: boolean
    setIsBestPracticeTriggered?: (value: boolean) => void
}

export const DialogContext = createContext<DialogContextType>({
    dialogStore,
    playerStore,
    isModalRestartOpen: false,
    isModalRegenerateOpen: false,
    isRegenerateTriggered: false,
    isModalSaveSentenceOpen: false,
    isModalDeleteSentenceOpen: false,
    isSwapTriggered: false,
    isModalSwapOpen: false,
    isModalBestPracticeOpen: false,
})

export const DialogProvider = ({ children }: { children: React.ReactNode }) => {
    const [isModalRestartOpen, setIsModalRestartOpen] = useState(false)
    const [isModalRegenerateOpen, setIsModalRegenerateOpen] = useState(false)
    const [isModalExportOpen, setIsModalExportOpen] = useState(false)
    const [isRegenerateTriggered, setIsRegenerateTriggered] = useState(false)
    const [isModalSaveSentenceOpen, setIsModalSaveSentenceOpen] = useState(false)
    const [isModalDeleteSentenceOpen, setIsModalDeleteSentenceOpen] = useState(false)
    const [isSwapTriggered, setIsSwapTriggered] = useState(false)
    const [isModalSwapOpen, setIsModalSwapOpen] = useState(false)
    const [isModalBestPracticeOpen, setIsModalBestPracticeOpen] = useState(false)
    const [isBestPracticeTriggered, setIsBestPracticeTriggered] = useState(false)

    return (
        <DialogContext.Provider
            value={{
                dialogStore,
                playerStore,
                isModalRestartOpen: isModalRestartOpen,
                setIsModalRestartOpen: setIsModalRestartOpen,
                isModalRegenerateOpen: isModalRegenerateOpen,
                setIsModalRegenerateOpen: setIsModalRegenerateOpen,
                isModalExportOpen: isModalExportOpen,
                setIsModalExportOpen: setIsModalExportOpen,
                isRegenerateTriggered: isRegenerateTriggered,
                isModalBestPracticeOpen: isModalBestPracticeOpen,
                setIsModalBestPracticeOpen: setIsModalBestPracticeOpen,
                isBestPracticeTriggered: isBestPracticeTriggered,
                setIsBestPracticeTriggered: setIsBestPracticeTriggered,
                setIsRegenerateTriggered: setIsRegenerateTriggered,
                isModalSaveSentenceOpen: isModalSaveSentenceOpen,
                setIsModalSaveSentenceOpen: setIsModalSaveSentenceOpen,
                isModalDeleteSentenceOpen: isModalDeleteSentenceOpen,
                setIsModalDeleteSentenceOpen: setIsModalDeleteSentenceOpen,
                isSwapTriggered: isSwapTriggered,
                setIsSwapTriggered: setIsSwapTriggered,
                isModalSwapOpen: isModalSwapOpen,
                setIsModalSwapOpen: setIsModalSwapOpen,
            }}
        >
            {children}
        </DialogContext.Provider>
    )
}

