import React, { useState, useEffect } from 'react';
import * as _Builtin from "../_Builtin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';

export function StarRating({ onSubmit }) {
    const [rating, setRating] = useState(5);
    const [hoveredRating, setHoveredRating] = useState(0);
    const [featureLiked, setFeatureLiked] = useState('');
    const [featureImprovement, setFeatureImprovement] = useState('');
    const [isMessageSending, setIsMessageSending] = useState(false);
    const [isMessageSent, setIsMessageSent] = useState(false);
    const [email, setEmail] = useState('');
    
    useEffect(() => {
        const user = sessionStorage.getItem('user')
        if (user) {
            const { email } = JSON.parse(user);
            setEmail(email);
        }
    }, []);

    const sendSlackMessage = async () => {
        setIsMessageSending(true);
        setIsMessageSent(false);
    
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer xoxb-1372204106790-7129913654544-YnOBwr7KhyY7F1ObHoMczKgf"
            },
            body: JSON.stringify({
                "channel": "C073HP1MVL1",
                "text": `Company-mail:${email}\nStar Rating: ${rating}\nFeature liked: ${featureLiked}\nFeature improvement: ${featureImprovement}`
            })
        };
    
        try {
            const response = await fetch("https://slack.com/api/chat.postMessage", requestOptions);
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            await response.text();
            setEmail('');
            setIsMessageSending(false);
            setIsMessageSent(true);
            setFeatureLiked('');
            setFeatureImprovement('');
            setRating(5);
            onSubmit();
        } catch (error) {
            console.error('Error sending message to Slack:', error);
            setIsMessageSending(false);
        }
    }

    return (
        <_Builtin.Block>
            {/* Star rating */}
            <_Builtin.Block className="flex-center margin-top margin-small">
                {[...Array(5)].map((_, index) => {
                    const starValue = index + 1;
                    const isFilled = starValue <= (hoveredRating || rating);
                    return (
                        <FontAwesomeIcon
                            className="flex-center margin-bottom margin-xsmall"
                            key={index}
                            icon={faStar}
                            style={{ color: isFilled ? '#ef5e10' : '#ccc', cursor: 'pointer' }}
                            onMouseEnter={() => setHoveredRating(starValue)}
                            onMouseLeave={() => setHoveredRating(0)}
                            onClick={() => {
                                setRating(starValue);
                                setIsMessageSent(false);
                            }}
                        />
                    );
                })}
            </_Builtin.Block>
            <_Builtin.Paragraph className="flex-center margin-top margin-xsmall text-color-grey">
                {'What did you like?'}
            </_Builtin.Paragraph>
            <_Builtin.FormTextarea
                className="input background-blue-150 w-input margin-top margin-xsmall"
                placeholder='(Optional)'
                value={featureLiked}
                onChange={e => setFeatureLiked(e.target.value)}
            />

            {/* Improvement input */}
            <_Builtin.Paragraph className="flex-center margin-top margin-xsmall text-color-grey">
                {'What could we improve?'}
            </_Builtin.Paragraph>
            <_Builtin.FormTextarea
                className="input background-blue-150 w-input margin-top margin-xsmall"
                placeholder='(Optional)'
                value={featureImprovement}
                onChange={e => setFeatureImprovement(e.target.value)}
            />

            {/* Send button */}
            <_Builtin.Block className="flex-center margin-top margin-xsmall">
                <_Builtin.Block className="button">
                    <_Builtin.Link
                        id="w-node-bdbf02d4-b080-8035-c3f8-2b8cd31b6aa8-d31b6a8e"
                        button={false}
                        block="inline"
                        onClick={sendSlackMessage} disabled={isMessageSending}
                    >
                        <_Builtin.Paragraph className="flex-center">
                            {isMessageSending ? 'Sending...' : 'Send it!'}
                        </_Builtin.Paragraph>
                    </_Builtin.Link>
                </_Builtin.Block>
            </_Builtin.Block>

            {/* Confirmation message */}
            {isMessageSent && (
                <_Builtin.Paragraph className="flex-center margin-top margin-xsmall">
                    {'Thank you for your contribution making the app better.'}
                </_Builtin.Paragraph>
            )}
        </_Builtin.Block>
    );
}