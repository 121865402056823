import { createContext } from 'react'

type Config = {
    CONTACT_EMAIL: string
    API_KEY: string
    API_CONFIG_1: string
    API_CONFIG_2: string
    API_DIAGEN: string
    API_DB: string
    API_AUTH: string
}

export const ConfigContext = createContext<Config | undefined>(undefined)

export const ConfigProvider = ({ children }: { children: React.ReactNode }) => {
    return (
        <ConfigContext.Provider value={(window as any).config}>
            {children}
        </ConfigContext.Provider>
    )
}
