import type {
    CreateRollRecordDto,
    RollRecord,
} from './roll-record.types'

/**
 * @description Maps the data from the API response and DTO to a RollRecord object.
 *
 * @param rollRecordDto - The DTO used for creating a roll record.
 * @param createdRollRecord - The response from the API after creating a roll record.
 * @returns Mapped RollRecord object.
 */
export function mapRollRecord(
    request: CreateRollRecordDto,
    response: string
): RollRecord {
    return {
        ...request,
        response: response,
    }
}
