import React from "react";
import * as _Builtin from "../_Builtin";
import * as _interactions from "../interactions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserGear } from '@fortawesome/free-solid-svg-icons'

export function AnimationContactSection({ as: _Component = _Builtin.Block }) {

  return (
    <_Component
      className="empty-animationtext-container padding-small background-color-blue200 rounded-8"
      tag="div"
    >
    <FontAwesomeIcon
        icon={faUserGear} size="2x"
        className="flex-center margin-bottom margin-xsmall"
      />
      <_Builtin.Paragraph className="margin-bottom margin-xsmall">
        {"Let's collaborate on your next project!"}
      </_Builtin.Paragraph>
      <_Builtin.Paragraph className="text-size-small text-color-blue40 max-width-xsmall text-align-center">
        {"Our teams are eager to connect with you and offer our tailored solutions."}
      </_Builtin.Paragraph>
    </_Component>
  );
}
