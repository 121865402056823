import { characterTypes } from 'entities/character'
import { DialogLines } from 'entities/dialog-line/dialog-line.types'
import { StateCreator } from 'zustand'

export type State = {
    characterStates: {
        character?: characterTypes.Npc
        startState: string
        endState: string
    }[]
    dialogLines: DialogLines
    startEndIndex: number
}

export type Actions = {
    setCharacter: (index: number, character?: characterTypes.Npc) => void
    setCharacterStartState: (index: number, state: string) => void
    setCharacterEndState: (index: number, state: string) => void
    setDialogLines: (state: DialogLines) => void
    setStartEndIndex: (index: number) => void
    save: () => void
    load: () => void
    reset: () => void
}

export type DialogState = State & Actions
export const createDialogSlice =
    (
        initialState: State
    ): StateCreator<
        DialogState,
        [['zustand/devtools', never]],
        [],
        DialogState
    > =>
    (set) => ({
        ...initialState,
        setCharacter: (index: number, character?: characterTypes.Npc) =>
            set((state) => ({
                characterStates: state.characterStates.map(
                    (characterState, i) =>
                        i === index
                            ? { ...characterState, character }
                            : characterState
                ),
            })),
        setCharacterStartState: (index: number, startState: string) =>
            set((state) => ({
                characterStates: state.characterStates.map(
                    (characterState, i) =>
                        i === index
                            ? { ...characterState, startState }
                            : characterState
                ),
            })),
        setCharacterEndState: (index: number, endState: string) =>
            set((state) => ({
                characterStates: state.characterStates.map(
                    (characterState, i) =>
                        i === index
                            ? { ...characterState, endState }
                            : characterState
                ),
            })),
        setDialogLines: (state: DialogLines) => set({ dialogLines: state }),
        setStartEndIndex: (index: number) => set({ startEndIndex: index }),
        save: () =>
            set((state) => {
                sessionStorage.setItem('dialogState', JSON.stringify(state))
                return state
            }),
        load: () => {
            const savedState = sessionStorage.getItem('dialogState')
            if (savedState) {
                set(JSON.parse(savedState))
            }
        },
        reset: () => {
            set(initialState)
            sessionStorage.removeItem('dialogState')
        },
    })
